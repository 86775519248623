'use strict';

angular.module('FormVersionUtils', ['MoreAppsUtils']);

angular.module('FormVersionUtils').service('$formVersionUtils', ['$uuidService', '$moreDataNameService', '$translate', function ($uuidService, $moreDataNameService, $translate) {

  const findAvailableFields = (rootFormVersion, formVersion, options, result = [], depth = 0) => {
    formVersion.fields.forEach(field => {
      if (result.indexOf(field) === -1) {
        field.$depth = depth;
        result.push(field);
      }
      if (options.traverseTargetViews) {
        if (field.properties.form) {
          findAvailableFields(rootFormVersion, field.properties.form, options, result, depth + 1);
        } else if (field.properties.target_form_id) {
          findAvailableFields(rootFormVersion, rootFormVersion.fieldProperties[field.properties.target_form_id], options, result, depth + 1);
        } else if (field.properties.pins) {
          for (const element of field.properties.pins) {
            if (element.form) {
              findAvailableFields(rootFormVersion, element.form, options, result, depth + 1);
            }
          }
        }
      }
    });
    return result;
  };

  const findNestedFields = (formVersion, view, path) => {
    let availableFields = [];
    view.fields.forEach(field => {
      let subpath = field.properties.data_name || field.uid;
      if (field.properties.form) {
        availableFields = availableFields.concat(findNestedFields(formVersion, field.properties.form, path ? path + '.' + subpath : subpath));
      } else if (field.properties.target_form_id) {
        availableFields = availableFields.concat(findNestedFields(formVersion, formVersion.fieldProperties[field.properties.target_form_id], path ? path + '.' + subpath : subpath));
      } else if (field.properties.pins) {
        for (let i = 0; i < field.properties.pins; i++) {
          if (field.properties.pins[i].form) {
            availableFields = availableFields.concat(findNestedFields(formVersion, field.properties.pins[i].form, path ? path + '.' + subpath : subpath));
          }
        }
      }
      let fieldPath = path ? path + '.' + subpath : subpath;
      if (availableFields.indexOf({fieldPath, field}) === -1) {
        availableFields.push({fieldPath, field});
      }
    });
    return availableFields;
  };

  const setupDataName = function (widget, target) {
    const widgetProperties = widget.definition.properties;
    const labelTextProperty = widgetProperties.find(property => property.key === 'label_text');
    if (labelTextProperty) {
      target.data_name = $moreDataNameService.asDataName(labelTextProperty.defaultValue);
      return;
    }

    const textPlaceholderProperty = widgetProperties.find(property => property.key === 'label_text');
    if (textPlaceholderProperty) {
      target.data_name = $moreDataNameService.asDataName(textPlaceholderProperty.defaultValue);
      return;
    }

    target.data_name = $moreDataNameService.asDataName(widget.name);
  };

  const getFields = view => findAvailableFields(view, view, {traverseTargetViews: false});
  const getFieldsRecursively = view => findAvailableFields(view, view, {traverseTargetViews: true});

  const getAttachments = view => getFields(view)
    .filter(field => field.widget === 'com.moreapps:pin:1')
    .map(field => field.properties.data_name);

  var setupBehaviour = function (widget, properties) {
    if (widget.definition.data === null || widget.definition.data === undefined) {
      return;
    }
    if (widget.definition.data.behaviour === null || widget.definition.data.behaviour === undefined) {
      return;
    }
    var behaviour = widget.definition.data.behaviour;
    if (behaviour.required && (properties.required === null || properties.required === undefined)) {
      properties.required = false;
    }
    if (behaviour.remember_input && (properties.remember_input === null || properties.remember_input === undefined)) {
      properties.remember_input = false;
    }
  };
  return {
    transformPropertiesToObject: widgetProperties => {
      let answer = {};

      widgetProperties.forEach(function (widgetProperty) {
        if (widgetProperty.type === 'list') {
          answer[widgetProperty.key] = angular.copy(widgetProperty.defaultValue) || [];
        } else if (widgetProperty.type === 'view_lookup') {
          if (widgetProperty.defaultValue) {
            answer[widgetProperty.key] = angular.copy(widgetProperty.defaultValue) || [];
          }
          answer.form = {
            uid: $uuidService.uid(),
            fields: [],
            rules: [],
            triggers: [buildSimpleMailTriggerForSubform()],
            settings: {
              interaction: 'MANUAL_UPLOAD',
              saveMode: 'SAVE_AND_CLOSE_ONLY',
              searchSettings: {}
            }
          };
        } else {
          answer[widgetProperty.key] = angular.copy(widgetProperty.defaultValue);
        }
      });

      // Get all uids used in the widget
      const uids = $.unique(JSONPath({path: '$..uid', json: answer}));

      // Replace them with new unique uids to prevent duplications in case of favourite widgets with nested uids (widgets, rules, etc.)
      const jsonString = uids.reduce((acc, i) => acc.replaceAll(i, $uuidService.uid()), JSON.stringify(answer));
      return JSON.parse(jsonString);
    },
    transformWidgetToField: function (widget) {
      let properties = this.transformPropertiesToObject(widget.definition.properties);
      var answer = {
        uid: $uuidService.uid(),
        widget: widget.info.uid,
        properties: properties
      };

      if (widget.definition.data) {
        setupDataName(widget, properties);
      }
      setupBehaviour(widget, properties);
      return answer;
    },
    getFields,
    getFieldsRecursively,
    findNestedFields,
    addEmailTrigger: (form, view) => {
      if (!view.triggers) {
        view.triggers = [];
      }

      const attachments = getAttachments(view);
      const formName = form.meta.name;
      const simpleMode = view.triggers.length === 0 || view.triggers[0].configuration.simpleMode;

      view.triggers.push({
        type: 'mail',
        name: $translate.instant('MAIL_NOTIFICATION'),
        content: getDefaultMailContent(formName),
        staticRecipients: [],
        dynamicRecipients: ['${sys.user}'],
        subject: 'Received registration for \'' + formName + '\'',
        pdfFilename: 'registration.pdf',
        configuration: {
          attachments: attachments,
          attachImages: true,
          attachPdf: true,
          embedImages: true,
          simpleMode: simpleMode,
          useHeaderAndFooter: true,
          hideNoValues: true
        }
      });
    },
    getAttachments,
    addRule: view => {
      if (!view.rules) {
        view.rules = [];
      }
      view.rules.push({type: 'AND', conditions: [{}], actions: [{}]});
    },
    newFormVersion: function (form) {
      let formVersion = {
        formId: form.id,
        fields: [],
        rules: [],
        triggers: [],
        integrations: [],
        meta: {status: 'FINAL'},
        settings: {
          interaction: 'IMMEDIATE_UPLOAD',
          saveMode: 'SAVE_AND_CLOSE_ONLY',
          icon: 'ios-paper-outline',
          searchSettings: {}
        }
      };
      this.addEmailTrigger(form, formVersion);
      return formVersion;
    },
    buildSimpleMailTriggerForSubform,
    copySubform
  };

  ////////
  function getDefaultMailContent(formName) {
    return 'Hi there!<br><br>' + 'A new registration has been received for form \'' + formName + '\'.<br>' + 'The registration has been attached as PDF.<br><br>' + 'Kind regards';
  }

  function buildSimpleMailTriggerForSubform() {
    return {
      type: 'mail',
      name: $translate.instant('MAIL_NOTIFICATION'),
      content: null,
      staticRecipients: [],
      dynamicRecipients: [],
      subject: null,
      pdfFilename: null,
      configuration: {
        attachments: [],
        attachImages: true,
        attachPdf: true,
        embedImages: true,
        simpleMode: true,
        useHeaderAndFooter: true,
        horizontalOrientation: false
      }
    };
  }

  function copySubform(subform) {
    const newSubform = angular.copy(subform);
    newSubform.uid = $uuidService.uid();
    if (newSubform.meta) {
      delete newSubform.meta;
    }
    return newSubform;
  }
}]);
