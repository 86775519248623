'use strict';

angular.module('FormManagement').directive('manageFormVersionUrl', ['$modalService', '$customerAuthenticationTokenService', 'moreConstants', function ($modalService, $customerAuthenticationTokenService, moreConstants) {

  return {
    restrict: 'E',
    template: require('../templates/directives/manage-form-url.html'),
    controllerAs: 'urlCtrl',
    bindToController: true,
    controller: ['$scope', function ($scope) {
      var self = this;
      var customerId = $scope.$state.params.customerId;
      var authenticationTokens;

      self.shareForm = shareForm;
      self.unshareForm = unshareForm;
      self.getDirectFormUrl = getDirectFormUrl;
      self.copyToClipboard = copyToClipboard;
      self.recompile = 0;
      self.formToken = null;

      init();

      ////////
      function init() {
        authenticationTokens = getTokens();
        findTokenForCurrentForm();

        $scope.$on('form.changed', function () {
          findTokenForCurrentForm();
          self.recompile++;
        });

        $scope.$watch('urlCtrl.formToken', function (newVal) {
          if (newVal) {
            self.directFormUrl = getDirectFormUrl();
          }
        });
      }

      function findTokenForCurrentForm() {
        authenticationTokens.$promise.then(function (tokens) {
          var found = tokens.filter(function (token) {
            return token.formId === $scope.form.id;
          });
          self.formToken = found.length > 0 ? found[0] : null;
        });
      }

      function getTokens() {
        return $customerAuthenticationTokenService.getAuthenticationTokens(customerId);
      }

      function shareForm(form) {
        $customerAuthenticationTokenService.createAuthenticationToken(customerId, form.id).$promise.then(function (token) {
          self.formToken = token;
          authenticationTokens = getTokens();
          self.directFormUrl = getDirectFormUrl();
        }, function () {
          $modalService.errorModal();
        });
      }

      function unshareForm(form, event) {
        event.preventDefault();
        $modalService.confirmModal({
          title: 'DIRECT_FORM_LINK_REVOKE_MODAL_TITLE',
          message: 'DIRECT_FORM_LINK_REVOKE_MODAL_MESSAGE',
          confirmButtonTitle: 'OK',
          showExclamation: true
        }).then(function () {
          $customerAuthenticationTokenService.revokeAuthenticationTokens(customerId, form.id).$promise.then(function () {
            self.formToken = null;
            authenticationTokens = getTokens();
            self.directFormUrl = null;
          }, function () {
            $modalService.errorModal();
          });
        });
      }

      function getDirectFormUrl() {
        var url = $scope.features.urls.webclient;
        if (url === 'https://app.moreapp.com') {
          url = moreConstants.webClientUrl; // override with localhost/develop/staging links
        }
        return url + '/form/' + self.formToken.token;
      }

      function copyToClipboard() {
        navigator.clipboard.writeText(self.directFormUrl);
      }
    }]
  };
}]);
