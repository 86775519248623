module.exports = {
  "FORMS": "Formulieren",
  "FOLDERS": "Mappen",
  "ADMINISTRATIVE": "Administratief",
  "INVITE": "Uitnodigen",
  "INVITES": "Uitnodigingen",
  "USER_MANAGEMENT_OVERVIEW": "Gebruikersoverzicht",
  "USER_MANAGEMENT_OVERVIEW_HEADER": "Gebruikersinformatie",
  "USER_MANAGEMENT_OVERVIEW_EDIT_USER": "Bewerk",
  "USER_MANAGEMENT_OVERVIEW_DELETE_USER": "Verwijder",
  "USER_MANAGEMENT_OVERVIEW_RESEND_INVITE": "Verstuur uitnodiging opnieuw",
  "USER_MANAGEMENT_OVERVIEW_NAME": "Naam",
  "USER_MANAGEMENT_OVERVIEW_ADMIN": "Admin",
  "USER_MANAGEMENT_OVERVIEW_NEWSLETTER": "Nieuwsbrief",
  "GROUPS": "Groepen",
  "ACCOUNT_OWNER": "Account Beheerder",
  "USER_MANAGEMENT_EMAIL_ADDRESS_LABEL": "E-mailadres",
  "USER_MANAGEMENT_ALL_USERS": "Alle Gebruikers",
  "USER_MANAGEMENT_NEW_TITLE": "Nieuwe Gebruiker(s)",
  "USER_MANAGEMENT_EDIT_TITLE": "Gebruikersbeheer",
  "USER_MANAGEMENT_PERMISSIONS_LABEL": "Toestemmingen",
  "USER_MANAGEMENT_PERMISSIONS_ADMIN": "Admin",
  "USER_MANAGEMENT_GROUP_PERMISSIONS_NO_GROUPS": "Geen groepen",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_LABEL": "Mappen",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_BY_GROUPLABEL": "(by group)",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_NO_COLLECTIONS": "Geen mappen",
  "USER_MANAGEMENT_EDIT_SECURITY": "Gebruiker Security",
  "USER_MANAGEMENT_SETTINGS_LABEL": "Gebruikersinstellingen",
  "USER_MANAGEMENT_SETTINGS_USERNAME": "Gebruikersnaam",
  "USER_MANAGEMENT_SETTINGS_FIRST_NAME": "Voornaam",
  "USER_MANAGEMENT_SETTINGS_LAST_NAME": "Achternaam",
  "USER_MANAGEMENT_SETTINGS_PHONE_NUMBER": "Telefoonnummer",
  "USER_MANAGEMENT_SETTINGS_RECEIVE_NEWS_LETTER": "Nieuwsbrief",
  "USER_MANAGEMENT_SETTINGS_DANGER_ZONE": "Danger Zone",
  "USER_MANAGEMENT_SETTINGS_REMOVE_USER": "Verwijder gebruiker",
  "CUSTOMER_GROUPS_NEW_TITLE": "Nieuwe Groep",
  "CUSTOMER_GROUPS_NAME_LABEL": "Naam",
  "CUSTOMER_GROUPS_NO_GROUPS": "Er zijn geen groepen.",
  "CUSTOMER_GROUPS_NO_VISIBLE_GROUPS": "Geen zichtbare groepen. Verander de filter.",
  "CUSTOMER_GROUPS_USER_PERMISSIONS": "Groepsleden",
  "CUSTOMER_GROUPS_ADD_BUTTON": "Voeg groep toe",
  "CUSTOMER_GROUPS_MEMBER_PREFIX": "Lid van",
  "CUSTOMER_GROUPS_EDIT_LABEL": "Bewerk",
  "CUSTOMER_GROUPS_REMOVE_LABEL": "Verwijder",
  "CUSTOMER_GROUPS_EDIT_TITLE": "Bewerk Groep",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS": "Toegang in de App",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS_EXPLANATION": "Deze groep heeft toegang tot de volgende mappen:",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS": "Platform Toestemmingen",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS_EXPLANATION": "Deze groep beheert:",
  "MANAGE_FORMS_LABEL": "Formulieren & Mappen",
  "MANAGE_REGISTRATIONS_LABEL": "Registraties",
  "MANAGE_TASKS_LABEL": "Taken",
  "MANAGE_USERS_LABEL": "Gebruikers",
  "MANAGE_SSO_LABEL": "Single sign-on",
  "MANAGE_DATA_SOURCES_LABEL": "Databronnen",
  "MANAGE_TEMPLATES_LABEL": "Templates",
  "MANAGE_WEBHOOKS_LABEL": "Webhooks",
  "TRANSFER_OWNERSHIP_LABEL": "Wijzigen Account Beheerder",
  "MANAGE_ACCOUNT_LABEL": "Algemene account instellingen",
  "USER_MANAGEMENT_GROUP_REMOVE_GROUP": "Verwijder Groep",
  "SELECT_GROUPS": "Selecteer groepen",
  "USER_COUNT": "Bevat {{value}} gebruikers",
  "USER_COUNT_SINGLE": "Bevat één gebruiker",
  "USER_COUNT_EMPTY": "Bevat geen gebruikers",
  "USER_MANAGEMENT_REMOVE_TITLE": "Verwijder Gebruiker",
  "USER_MANAGEMENT_REMOVE_MESSAGE": "Weet je zeker dat je deze gebruiker wil verwijderen?",
  "USER_MANAGEMENT_REMOVE_YOURSELF_TITLE": "Je kunt jezelf niet verwijderen",
  "USER_MANAGEMENT_REMOVE_YOURSELF_MESSAGE": "Vraag de eigenaar van het account om jou te verwijderen.",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_TITLE": "Verwijder gebruiker uit groep",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_MESSAGE": "Weet je zeker dat je de gebruiker uit deze groep wil verwijderen?",
  "USER_MANAGEMENT_REMOVE_INVITE_TITLE": "Verwijder Uitnodiging",
  "USER_MANAGEMENT_INVITE_REMOVE_MESSAGE": "Weet je zeker dat je deze uitnodiging wil verwijderen?",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_TITLE": "Verwijder genodigde uit de groep",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_MESSAGE": "Weet je zeker dat je de genodigde uit deze groep wil verwijderen?",
  "USER_MANAGEMENT_GROUP_REMOVE_TITLE": "Verwijder Groep",
  "USER_MANAGEMENT_GROUP_REMOVE_MESSAGE": "Weet je zeker dat je deze groep wilt verwijderen? Je kunt dit niet ongedaan maken! Hiermee verwijder je niet de gebruikers in deze groep. ",
  "USER_MANAGEMENT_IMPORTED_MODAL_TITLE": "Gebruikers zijn uitgenodigd",
  "USER_MANAGEMENT_IMPORTED_MODAL_MESSAGE": "Je hebt met succes <strong>{{count}} gebruikers</strong> uitgenodigd.",
  "USER_MANAGEMENT_EXCEL_FILE_TOO_LARGE_MESSAGE": "Het geselecteerde document is groter dan 5MB",
  "USER_MANAGEMENT_INVITE_TYPE": "Hoeveel gebruikers wil je uitnodigen?",
  "USER_MANAGEMENT_INVITE_SINGLE": "Eén gebruiker",
  "USER_MANAGEMENT_INVITE_BULK": "Meerdere gebruikers",
  "USER_MANAGEMENT_INVITE_BULK_MESSAGE": "Selecteer een Excel-bestand welke een lijst van e-mailadressen bevat in de eerste kolom.",
  "USER_MANAGEMENT_INVITE_LANGUAGE": "Taal van uitnodiging",
  "USER_MANAGEMENT_INVITE_FAILED_TITLE": "Gebruiker uitnodigen mislukt",
  "USER_MANAGEMENT_INVITE_FAILED_MESSAGE": "De gebruiker is al toegevoegd aan dit account",
  "USER_MANAGEMENT_PERMISSION_CHANGE_FAILED_TITLE": "Verandering in toestemmingen mislukt",
  "USER_MANAGEMENT_GROUP_REMOVE_FAILED": "Kon de groep niet verwijderen, omdat je hierdoor geen toestemming meer hebt om gebruikers te beheren.",
  "USER_MANAGEMENT_GROUP_PERMISSION_CHANGE_FAILED": "Kon de toestemming niet verwijderen, omdat je hierdoor geen toestemming meer hebt om gebruikers te beheren.",
  "ROLES": "Rollen",
  "ROLES_DEFAULT": "Standaardrollen",
  "ROLES_CUSTOM": "Aangepaste rollen",
  "MANAGE_ROLES_TITLE": "Beheer rollen",
  "MANAGE_ROLES_DESCRIPTION": "Definieer de rechten die bij jouw rollen horen.",
  "MANAGE_BILLING": "Beheer betalingen",
  "MANAGE_CREDITS": "Beheer betalingen",
  "MANAGE_DATA_SOURCES": "Beheer databronnen",
  "MANAGE_FORMS": "Beheer alle formulieren & mappen",
  "MANAGE_TASKS": "Beheer alle taken",
  "MANAGE_REGISTRATIONS": "Beheer alle registraties",
  "MANAGE_USERS": "Beheer gebruikers & rechten",
  "MANAGE_SSO": "Beheer single sign-on",
  "MANAGE_SENDING_DOMAIN": "Beheer e-mail domein",
  "MANAGE_WEBHOOKS": "Beheer webhooks",
  "MANAGE_TEMPLATES": "Beheer sjablonen",
  "MANAGE_ACCOUNT": "Beheer Account",
  "TRANSFER_OWNERSHIP": "Wijzig Account Beheerder",
  "CUSTOMER_USER": "App toegang",
  "CREATE_FOLDER": "Maak mappen",
  "CREATE_FORM": "Maak formulieren",
  "ADD_FORM": "Voeg formulier toe aan map",
  "REMOVE_FORM": "Verwijder formulier uit map",
  "UPDATE_FOLDER": "Update map",
  "DELETE_FOLDER": "Verwijder map",
  "CREATE_SUBMISSION": "Maak registratie",
  "READ_SUBMISSION": "Bekijk registraties",
  "DELETE_SUBMISSION": "Verwijder registraties",
  "UPDATE_SUBMISSION": "Update registraties",
  "READ_TASK": "Bekijk taken",
  "UPDATE_TASK": "Voltooi taken",
  "CREATE_TASK": "Maak taken",
  "DELETE_TASK": "Taken intrekken",
  "UPDATE_FORM": "Update formulier",
  "ARCHIVE_FORM": "Verwijder formulier",
  "RESTORE_FORM": "Herstel formulier",
  "MANAGE_BILLING_TOOLTIP": "Update jouw abonnement, betaalinformatie en betaalmethode. Bekijk facturen en het gebruik.",
  "MANAGE_CREDITS_TOOLTIP": "Update jouw abonnement, betaalinformatie en betaalmethode. Bekijk facturen en het gebruik.",
  "MANAGE_DATA_SOURCES_TOOLTIP": "Maak, bekijk, update en verwijder alle databronnen",
  "MANAGE_FORMS_TOOLTIP": "Maak, bekijk en update <b>ALLE</b> formulieren en mappen",
  "MANAGE_TASKS_TOOLTIP": "Maak, bekijk en update taken voor <b>ALLE</b> formulieren",
  "MANAGE_REGISTRATIONS_TOOLTIP": "Bekijk, verwijder en verstuur registraties opnieuw voor <b>ALLE</b> formulieren",
  "MANAGE_USERS_TOOLTIP": "Beheer de rechten van alle gebruikers, genodigden en groepen",
  "MANAGE_SSO_TOOLTIP": "Beheer je configuratie voor single sign-on",
  "MANAGE_SENDING_DOMAIN_TOOLTIP": "Beheer configuratie voor het personaliseren van het e-mail domein",
  "MANAGE_WEBHOOKS_TOOLTIP": "Maak, bekijk, update en verwijder alle webhooks",
  "MANAGE_TEMPLATES_TOOLTIP": "Maak, bekijk, update en verwijder alle sjablonen",
  "MANAGE_ACCOUNT_TOOLTIP": "Beheer algemene account instellingen",
  "TRANSFER_OWNERSHIP_TOOLTIP": "Beheer algemene account instellingen",
  "CREATE_FOLDER_TOOLTIP": "Maak nieuwe mappen",
  "CREATE_FORM_TOOLTIP": "Maak formulier aan in een map waar je bij kunt",
  "ADD_FORM_TOOLTIP": "Toevoegen aan/verplaatsen naar een map waar je bij kunt",
  "REMOVE_FORM_TOOLTIP": "Verwijderen uit een map waar je bij kunt",
  "UPDATE_FOLDER_TOOLTIP": "Update naam, omschrijving en icoon.",
  "DELETE_FOLDER_TOOLTIP": "Verwijder map",
  "CREATE_SUBMISSION_TOOLTIP": "Verstuur formulieren met de app",
  "READ_SUBMISSION_TOOLTIP": "Bekijk registraties van een specifiek formulier",
  "DELETE_SUBMISSION_TOOLTIP": "Verwijder registraties van een specifiek formulier",
  "UPDATE_SUBMISSION_TOOLTIP": "Update de registraties van een specifiek formulier",
  "CREATE_TASK_TOOLTIP": "Maak taken voor een specifiek formulier",
  "READ_TASK_TOOLTIP": "Bekijk taken voor een specifiek formulier",
  "UPDATE_TASK_TOOLTIP": "Voltooi taken van een specifiek formulier",
  "DELETE_TASK_TOOLTIP": "Trek taken in van een specifiek formulier",
  "UPDATE_FORM_TOOLTIP": "Formulier bewerken en publiceren",
  "ARCHIVE_FORM_TOOLTIP": "Formulier verwijderen",
  "ALLOWED": "Ja",
  "ALLOW": "Toestaan",
  "DONT_ALLOW": "Sta niet toe",
  "NOT_ALLOWED": "Nee",
  "ACCESS_ON_FOLDERS_AND_FORMS": "Mappen en Formulieren",
  "ACCESS_ON_FOLDERS_AND_FORMS_DESCRIPTION": "Beheer de rechten van alle gebruikers in deze groep.",
  "GROUP_ALL_FORMS_ACCESS_VIA_ROLE": "Deze groep kan <b>alle formulieren en mappen beheren</b> vanuit de rol hierboven benoemd",
  "USER_ALL_FORMS_ACCESS_VIA_ROLE": "Deze gebruiker kan <b>alle formulieren en mappen beheren</b> vanuit de rol",
  "ACCESS_ON_FOLDERS_AND_FORMS_USER_DESCRIPTION": "Beheer de rechten van deze gebruiker.",
  "ACCESS_ON_ACCOUNT": "Account",
  "ACCESS_ON_ACCOUNT_GROUP_DESCRIPTION": "Selecteer een accountrol voor alle gebruikers in deze groep.",
  "ACCESS_ON_ACCOUNT_USER_DESCRIPTION": "Selecteer een accountrol voor deze gebruiker.",
  "NEW_ROLE": "Maak een nieuwe rol",
  "USER_IN_GROUPS_DESCRIPTION": "Deze gebruiker is toegevoegd aan de volgende groepen",
  "USER_NOT_IN_GROUP_DESCRIPTION": "Deze gebruiker is aan geen enkele groep toegevoegd",
  "ACCESS_VIA_GROUPS": "Rechten vanuit groepen",
  "ROLE_ACCOUNT_OWNER": "Account Beheerder",
  "ROLE_ACCOUNT_ADMINISTRATOR": "Account Administrator",
  "ROLE_OWNER": "Account Beheerder",
  "ROLE_USER": "Account Gebruiker",
  "ROLE_FORM_MANAGER": "Formulier Beheerder",
  "ROLE_FORM_USER": "Formulier Gebruiker",
  "ROLE_FOLDER_MANAGER": "Mappen Beheerder",
  "USER_DESCRIPTION": "Gebruikers informatie",
  "ROLE_DELETE_TITLE": "Verwijder rol",
  "ROLE_DELETE_MESSAGE": "Je kunt alleen rollen verwijderen die niet in gebruik zijn. Zorg ervoor dat je eerst alle gebruikers/groepen aanpast die deze rol hebben.",
  "EXTERNAL_DIRECTORY_GROUP_LABEL": "Extern",
  "EXTERNAL_DIRECTORY_GROUP_DESCRIPTION": "Deze groep wordt gesynchroniseerd vanuit de externe directory en kan niet worden gewijzigd.",
  "EXTERNAL_DIRECTORY_USER_LABEL": "Extern",
  "EXTERNAL_DIRECTORY_USER_DESCRIPTION": "Deze gebruiker wordt gesynchroniseerd vanuit je externe directory en kan niet worden gewijzigd.",
  "USER_SUSPENDED": "Geblokkeerd",
  "USER_SUSPENDED_TOOLTIP": "Deze gebruiker is geblokkeerd in jouw externe directory",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "Niet toegestaan om Account Beheerder te wijzigen",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "Je bent niet geautoriseerd om de Account Beheerder over te dragen aan de uitgenodigde gebruiker. De gebruiker dient de uitnodiging eerst te accepteren.",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "Niet toegestaan om Account Beheerder te wijzigen",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "Je dient beheerder te zijn van het account om de rol over te kunnen dragen aan een andere gebruiker.",
  "TRANSFER_OWNERSHIP_CONFIRM_TITLE": "Wijzig Account Beheerder",
  "TRANSFER_OWNERSHIP_CONFIRM_MESSAGE": "Weet je zeker dat je de beheerdersrechten wil overdragen aan <em>{{firstName}} {{lastName}} ({{username}})</em>?<br /> <br /> <b>Note:</b> Jij bent niet langer Account Beheerder van het account.",
  "TRANSFER_OWNERSHIP_CONFIRMED_TITLE": "Wijzig Account Beheerder",
  "TRANSFER_OWNERSHIP_CONFIRMED_MESSAGE": "<em>{{firstName}} {{lastName}} ({{username}})</em> is de huidige beheerder van dit account.",
  "MENU_WIDGETS": "Widgets",
  "MENU_EMAIL": "E-mail",
  "MENU_RULES": "Regels",
  "MENU_HOOKS": "Integraties",
  "SUB_FORM_NO_LONGER_USED_TITLE": "Subformulier niet gebruikt in de map",
  "SUB_FORM_NO_LONGER_USED_BODY": "Het subformulier wordt niet meer gebruikt. Wil je het verwijderen?",
  "MULTIPLE_SUB_FORM_NO_LONGER_USED_BODY": "Deze subformulieren worden niet meer gebruikt in de map. Wil je deze verwijderen?",
  "UNSUPPORTED_FILE_TYPE": "Deze bestandtypes worden ondersteund: {{fileTypes}}",
  "FORM_FINALIZED_TITLE": "Deze versie van het formulier kan niet gewijzigd worden",
  "FORM_FINALIZED_MESSAGE": "Als je het formulier wil bewerken, ga dan terug naar het overzicht en klik op 'Bewerk'.",
  "PUBLISH_FAILED_TITLE": "Formulier publiceren mislukt",
  "PUBLISH_FAILED_MESSAGE": "Geen zorgen, je formulier is succesvol opgeslagen, alleen het publiceren is mislukt. Probeer het later opnieuw. Als dit probleem zich blijft voordoen, neem dan contact met ons op.",
  "INTEGRATION_EOL_MESSAGE": "Deze integratie ondersteunen wij binnenkort niet meer. Verwijder of vervang deze door een andere integratie of webhook. Voor vragen of advies <a href=\"https://help.moreapp.com/en/support/tickets/new\">neem contact op met Support</a>.",
  "USER_PICKER_ADD_USER": "Gebruiker(s) uitnodigen",
  "USER_PICKER_ADD_GROUP": "Nieuwe groep",
  "USER_PICKER_ALL_USERS": "Alle gebruikers",
  "USER_PICKER_INVITES": "Uitnodigingen",
  "USER_PICKER_INVITED": "(Uitgenodigd)",
  "COLLECTION_PICKER_ADD_FORM": "Nieuw Formulier",
  "COLLECTION_PICKER_ADD_COLLECTION": "Nieuwe Map",
  "SEARCH_FORMS_PLACEHOLDER": "Zoek naar mappen en formulieren",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECT_COLUMNS": "Selecteer kolommen",
  "VIEW_MANAGEMENT_REGISTRATIONS_SEARCH": "Zoek",
  "VIEW_MANAGEMENT_REGISTRATIONS_VIEW_REGISTRATION": "Bekijk",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL": "Alles verwijderen",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_TITLE": "Weet je het zeker?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_MESSAGE": "Weet je zeker dat je alle registraties van dit formulier wilt verwijderen? Je kunt dit niet ongedaan maken!",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_REGISTRATIONS": "Dit delete alle registraties van dit formulier, niet alleen de geselecteerde",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_VERIFICATION_MESSAGE": "Voer je klantnummer in om te bevestigen",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT": "Exporteer",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_ALL": "Exporteer Alles",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_FILTERED": "Gefilterd Exporteren",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SELECTED": "Exporteer geselecteerde",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_IMAGES": "Afbeeldingen meenemen",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_INCLUDE_FILES": "Voeg bestanden toe",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SINGLE_SHEET": "Exporteren naar een enkel Excel-blad",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_TITLE": "Exporteren kan even duren",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_MESSAGE": "Je staat op het punt om een aantal registraties te exporteren. Dit kan even duren om te downloaden.",
  "VIEW_MANAGEMENT_REGISTRATIONS_NO_USERS": "Er zijn geen gebruikers in dit formulier.",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECTED": "Geselecteerd",
  "VIEW_MANAGEMENT_REGISTRATIONS_CLEAR_SELECTED": "Selectie Ongedaan Maken",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_TITLE": "Registratie Details",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_BUTTON": "Verwijder registratie",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_TITLE": "Verwijder registratie permanent",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_MESSAGE": "Weet je zeker dat je de registratie wilt verwijderen? Dit kan niet ongedaan gemaakt worden.",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_BUTTON": "Verstuur registratie opnieuw",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_TITLE": "Verstuur registratie opnieuw",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_MESSAGE": "Wil je deze registratie opnieuw versturen?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_SUCCESS_MESSAGE": "Registratie is verzonden",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_ERROR_MESSAGE": "Opnieuw versturen registratie mislukt. Neem contact op met support, indien dit blijft.",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION": "E-mails met PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_RECIPIENTS": "Ontvangers",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_PDF": "PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION": "Info",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION_USER": "Gebruiker",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DATA_SECTION": "Data",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_FILE_DOWNLOAD": "Alle bestanden",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION": "Meta",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_LOCATION": "Locatie",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_SHOW_MAP": "Tonen in Google Maps",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NO_LOCATION": "Niet opgenomen",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DATE": "Datum van opslaan",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DEVICE": "Apparaat",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NETWORK": "Netwerk",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_APP_VERSION": "App versie",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_ID": "ID",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_TASK": "Taak",
  "SERIAL_NUMBER": "Serienummer",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_TITLE": "Integraties",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_RETRY": "Herhaal stap {{index}}",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_DOWNLOAD_REPORT": "Download report",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_NEXT_EXECUTION": "Volgende poging in",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_TITLE": "E-mail",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_DOWNLOAD_PDF": "Download PDF",
  "VIEW_MANAGEMENT_MAIL_STATUS_RESEND_ATTEMPT": "Verstuur poging opnieuw",
  "DOWNLOAD": "Download",
  "MAIL_STATUS_SEND": "{{number}} verzonden",
  "MAIL_STATUS_QUEUED": "{{number}} in de rij",
  "MAIL_STATUS_SOFT_BOUNCE": "{{number}} soft bounced",
  "MAIL_STATUS_HARD_BOUNCE": "{{number}} hard bounced",
  "MAIL_STATUS_REJECT": "{{number}} geweigerd",
  "BAD_MAILBOX": "Onbekend e-mailadres",
  "INVALID_DOMAIN": "Ongeldig domein",
  "IMAGES": "Afbeeldingen",
  "UNPAID_SUBMISSION": "Deze registratie is nog niet verwerkt.",
  "TRY_BETA_EXPORT": "Probeer de nieuwe bètaversie",
  "EXPORT_ALL_COLUMNS": "Exporteer alle kolommen",
  "SELECTED_COLUMNS": "Selecteer kolommen",
  "EXPORT_OPTIONS_TIMEZONE": "Tijdzone",
  "EXPORT_SUCCESS_TITLE": "Export succesvol gestart",
  "EXPORT_SUCCESS_MESSAGE": "De export is gestart, zodra deze is voltooid, wordt deze verzonden naar <b>{{email}}</b>. Afhankelijk van de grootte van jouw export kan dit tot enkele uren duren.",
  "EXPORT_SERVICE_UNAVAILABLE": "Het is op dit moment niet mogelijk om te exporteren. Probeer het later nog eens.",
  "EXPORT_FAILED_FILES_SUBMISSION_LIMIT": "Export inclusief bestanden is beperkt tot {{limit}} registraties. ",
  "MORE_INFORMATION": "Meer informatie",
  "SELECTABLE_REORDARABLE_DONE": "Klaar",
  "ACCEPT_CUSTOMER_INVITE": "Wil je de uitnodiging van '{{customer}}' accepteren?",
  "ACCEPT_BUTTON": "Accepteren",
  "ACTIVATION_FORM_TITLE": "Account activatie",
  "ACTIVATION_CUSTOMER_INFORMATION_TITLE": "Klantinformatie",
  "ACTIVATION_CUSTOMER_INFORMATION_NAME": "Naam",
  "ACTIVATION_USER_INFORMATION_TITLE": "Gebruikersinformatie",
  "ACTIVATION_USER_INFORMATION_FIRST_NAME": "Voornaam",
  "ACTIVATION_USER_INFORMATION_LAST_NAME": "Achternaam",
  "ACTIVATION_PASSWORD": "Wachtwoord",
  "ACTIVATION_PASSWORD_CONFIRM": "Wachtwoord bevestigen",
  "ACTIVATION_PASSWORD_MISMATCH": "Wachtwoorden komen niet overeen. Zorg ervoor dat de ingevoerde wachtwoorden gelijk zijn.",
  "ACTIVATION_FORM_TOC": "Algemene voorwaarden",
  "ACTIVATION_FORM_TOC_MESSAGE": "Ik ga akkoord met de ",
  "ACTIVATION_SUBMIT_BUTTON": "Activeren",
  "ACTIVATION_ENTER_TOKEN_TITLE": "Vul token in",
  "ACTIVATION_TOKEN_ENTRY_TOKEN": "Token",
  "ACTIVATION_INVALID_TOKEN_TITLE": "Je uitnodiging is verlopen of ongeldig",
  "ACTIVATION_INVALID_TOKEN_MESSAGE": "Vraag je administrator om je opnieuw uit te nodigen",
  "ACTIVATION_CHECK_TOKEN": "Controleer token",
  "ACTIVATION_SUCCESS": "Je account is geactiveerd!",
  "LICENSE_AGREEMENT": "Licentieovereenkomst",
  "PRIVACY_POLICY": "Privacyverklaring",
  "SECURITY_STATEMENT": "Beveiligingsverklaring",
  "LOGIN_ON_MOBILE_APP": "Login in de app:",
  "APP_DOWNLOAD_INSTRUCTIONS": "Download de app op jouw Android of iOS toestel om te beginnen.",
  "OR": "of",
  "SCAN_QR_CODE": "Scan QR-code",
  "NO_SMARTPHONE": "Geen smartphone bij de hand?",
  "OPEN_IN_WEBCLIENT": "Openen in browser",
  "ICON_PICKER_SELECT": "Selecteer icoon",
  "ICON_PICKER_MODAL_TITLE": "Selecteer icoon",
  "MORE_GRID_VIEW": "Details",
  "MORE_GRID_LOAD_MORE": "Laad meer",
  "MORE_GRID_OBJECT_SHOW_DETAILS": "Details",
  "MORE_GRID_OBJECT_DETAILS_HEADER": "Details",
  "DATE_FILTER_FROM_LABEL": "Van",
  "DATE_FILTER_TO_LABEL": "Tot",
  "DATE_FILTER_PLACEHOLDER": "Datum",
  "NO_RESULTS_FOUND": "Geen resultaten gevonden",
  "CANVAS_NOT_SUPPORTED_TITLE": "Handtekening niet ondersteund",
  "CANVAS_NOT_SUPPORTED_MESSAGE": "Dit werkt alleen in de app en webclient.",
  "PHOTO_CAPTURE_NOT_SUPPORTED_TITLE": "Foto niet ondersteund",
  "PHOTO_CAPTURE_NOT_SUPPORTED_MESSAGE": "Dit werkt alleen in de app en webclient.",
  "PHOTO_LIBRARY_NOT_SUPPORTED_TITLE": "Foto niet ondersteund",
  "PHOTO_LIBRARY_NOT_SUPPORTED_MESSAGE": "Dit werkt alleen in de app en webclient.",
  "BARCODE_SCAN_FAILURE": "Barcodescanner niet ondersteund",
  "BARCODE_NOT_SUPPORTED_MESSAGE": "Dit werkt alleen in de app en webclient.",
  "DATE_MODAL_TITLE": "Datum kiezen",
  "WIDGET_DETAIL_LINK": "Subformulier kan hergebruikt worden in andere Subform-widgets",
  "WIDGET_DETAIL_UNLINK": "Deze Subform-widget is gekoppeld aan een ander subformulier",
  "WIDGET_DETAIL_NEW_TITLE": "Nieuw subformulier",
  "WIDGET_DETAIL_REMOVE_TITLE": "Verwijder subformulier",
  "WIDGET_DETAIL_LINK_DESCRIPTION": "Kies een bestaand subformulier",
  "WIDGET_DETAIL_LINKED_DESCRIPTION": "Wijzigingen worden doorgevoerd in de andere Subform-widgets op basis van dit subformulier",
  "WIDGET_DETAIL_USAGE_DESCRIPTION": "Gebruikt in {{value}} widgets",
  "WIDGET_DETAIL_USAGE_DESCRIPTION_UNUSED": "Niet verbruikt",
  "WIDGET_DETAIL_REMOVE_MESSAGE": "Weet je zeker dat je dit wilt verwijderen?",
  "COLLECTION": "Map",
  "DESCRIPTION": "Omschrijving",
  "LOGIN": "Inloggen",
  "LOGIN_USERNAME": "E-mailadres",
  "LOGIN_PASSWORD": "Wachtwoord",
  "LOGIN_SIGN_IN_BUTTON": "Inloggen",
  "LOGIN_INVALID_USERNAME_PASSWORD_COMBINATION": "Ongeldige e-mailadres / wachtwoord combinatie",
  "LOGIN_FORGOT_PASSWORD_LINK": "Wachtwoord vergeten",
  "LOGIN_NO_ACCOUNT_MESSAGE": "Geen account? Kies jouw abonnement hier!",
  "LOGOUT_SUCCESSFUL_TITLE": "Uitgelogd",
  "LOGOUT_SUCCESSFUL_MESSAGE": "Je bent nu uitgelogd",
  "LOGOUT_SUCCESSFUL_ACTION": "Log weer in",
  "FORGOT_PASSWORD_TITLE": "Wachtwoord vergeten",
  "FORGOT_PASSWORD_EMAIL": "E-mailadres",
  "FORGOT_PASSWORD_USERNAME": "E-mailadres",
  "FORGOT_PASSWORD_SUBMIT": "Verzenden",
  "FORGOT_PASSWORD_INVALID_EMAIL": "Ongeldig e-mailadres",
  "FORGOT_PASSWORD_EMAIL_SENT": "Je wachtwoord is gereset. Volg de link in de e-mail die je hebt ontvangen om een nieuw wachtwoord te kiezen.",
  "FORGOT_PASSWORD_BACK_TO_LOGIN": "Terug naar inloggen",
  "ACTIVATE_USER_TITLE": "Activeer gebruiker",
  "ACTIVATE_USER_TICKET": "Ticket",
  "ACTIVATE_USER_NEW_PASSWORD_INPUT": "Nieuw wachtwoord",
  "ACTIVATE_USER_NEW_PASSWORD_VERIFY_INPUT": "Nieuw wachtwoord (verificatie)",
  "MENU_HELP": "Help Center",
  "MENU_REGIONS": "Regio's",
  "MENU_CUSTOMERS": "Klanten",
  "MENU_LOGOUT": "Uitloggen",
  "MENU_USERS": "Gebruikers",
  "MENU_REGISTRATIONS": "Registraties",
  "MENU_FORMS": "Formulieren",
  "MENU_TEMPLATES": "Sjablonen",
  "MENU_SETTINGS": "Instellingen",
  "MENU_API_DOC": "Docs",
  "MENU_DOCUMENTATION": "Mail doc",
  "MENU_SUPPORT": "Support",
  "MENU_PLUGINS": "Plug-ins",
  "MENU_TRANSACTIONS": "Transacties",
  "MENU_BILLING": "Betalingen",
  "MENU_FINANCIAL": "Financieel",
  "MENU_CONTACT": "Contact",
  "MENU_FAQ": "FAQ",
  "MENU_CHOOSE_CUSTOMER_LABEL": "Kies klant",
  "MENU_CLIENTS": "Apps",
  "MENU_DATA_SOURCES": "Databronnen",
  "MENU_PROFILE": "Profiel",
  "MENU_ONBOARDING": "Aanmelden",
  "MENU_QUEUE": "Wachtrij",
  "PROFILE_INFO": "Info",
  "PROFILE_EMAIL_VALID": "E-mail is gevalideerd.",
  "PROFILE_EMAIL_NOT_VALIDATED": "E-mail is nog niet gevalideerd",
  "SUBMITTED": "Verzonden",
  "SENT": "Verzonden",
  "ACCEPTED": "Goedgekeurd",
  "REVOKED": "Geannuleerd",
  "REJECTED": "Afgewezen",
  "CONFIRM": "Bevestig",
  "AWAITING_PACKAGE_UPLOAD": "Wacht op zipbestand",
  "RESELLER": "Reseller",
  "KICKBACK": "Kickback",
  "CUSTOMER_APPLICATION_SETTINGS": "Instellingen",
  "CUSTOMER_APPLICATION_OPERATIONS": "Map acties",
  "CUSTOMER_FOLDER_FORMS_ACTIVE": "Map kan alleen worden verwijderd als deze geen formulieren bevat",
  "CUSTOMER_USAGE_TOTAL_FORMS_SENT": "Totaal aantal verzonden formulieren",
  "CUSTOMER_USAGE_STATISTICS_FORMS_SENT": "Verzonden formulieren",
  "SIMULATOR_SEARCH_WIDGET_PLACEHOLDER": "Zoek...",
  "ADD_FORM_PANEL_TITLE": "Nieuw formulier",
  "ADD_FORM_PANEL_TITLE_LABEL": "Naam",
  "ADD_FORM_PANEL_TITLE_PLACEHOLDER": "Naam",
  "ADD_FORM_PANEL_SAVE": "Opslaan",
  "ADD_TEMPLATE": "Nieuw sjabloon",
  "MARK_GLOBAL": "Maak zichtbaar",
  "UNMARK_GLOBAL": "Maak onzichtbaar",
  "MARK_GLOBAL_TOOLTIP": "Alleen beschikbaar voor MoreApp ADMINS. Maak zichtbaar om de template beschikbaar te maken voor alle klanten vanuit de + Nieuw Formulier optie",
  "ADD_APPLICATION_PANEL_TITLE": "Nieuwe map",
  "ADD_APPLICATION_PANEL_TITLE_LABEL": "Naam",
  "ADD_APPLICATION_PANEL_TITLE_PLACEHOLDER": "Naam",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_TITLE": "Formulier verwijderen",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_MESSAGE": "Weet je zeker dat je dit formulier wil verwijderen?",
  "CUSTOMER_APPLICATIONS_COPY_FORM_TITLE": "Formulier kopiëren",
  "CUSTOMER_APPLICATIONS_COPY_FORM_MESSAGE": "Kies een map",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_LABEL": "Map",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_PLACEHOLDER": "Map",
  "CUSTOMER_APPLICATIONS_COPY_FORM_NAME_LABEL": "Naam van de kopie",
  "CUSTOMER_APPLICATIONS_ACTIONS_DEPLOY": "Publiceren",
  "CUSTOMER_APPLICATIONS_ACTIONS_USER_MANAGEMENT": "Gebruikersbeheer",
  "CUSTOMER_APPLICATIONS_ACTIONS_COPY": "Kopie",
  "CUSTOMER_APPLICATIONS_ACTIONS_DELETE": "Verwijder",
  "CUSTOMER_APPLICATION_COPY_TITLE": "Map kopiëren",
  "CUSTOMER_APPLICATION_CUSTOMER_LABEL": "Klant",
  "CUSTOMER_APPLICATION_DEPLOY_TITLE": "Map publiceren",
  "CUSTOMER_APPLICATION_DEPLOY_QUESTION": "Weet je zeker dat je de map wil publiceren?",
  "CUSTOMER_APPLICATION_DEPLOY_SUCCESS_MESSAGE": "De map is gepubliceerd!",
  "CUSTOMER_APPLICATION_DEPLOY_ERROR_MESSAGE": "Het is niet gelukt om de map te publiceren. Controleer de formulieren in de map. Neem contact op met je partner als het je niet lukt.",
  "CUSTOMER_APPLICATION_DEPLOY_VALIDATION_MESSAGE": "De map is niet gepubliceerd. Los de volgende fouten op en probeer het nog een keer.",
  "CUSTOMER_APPLICATION_DEPLOY_LAST_VALIDATION_MESSAGE": "De vorige poging bevatte de volgende fouten.",
  "CUSTOMER_APPLICATION_DEPLOY_SHOW_LAST_VALIDATION_BTN": "Toon eerdere fouten.",
  "CUSTOMER_APPLICATION_DEPLOY_BUTTON": "Publiceren",
  "CUSTOMER_APPLICATION_PLACE_ON_MARKETPLACE_TITLE": "Voeg toe aan de Marketplace",
  "INVALID_APPLICATION_HEADER": "Applicatie fouten:",
  "INVALID_FORM_HEADER": "Formulier fouten:",
  "INVALID_APPLICATION_START_PAGE": "Voeg een startpagina toe aan de map",
  "INVALID_APPLICATION_NAME": "Geef de map een naam",
  "INVALID_APPLICATION_THEME": "Voeg een thema toe aan de map",
  "INVALID_FORM_SEARCH_SETTINGS_ITEM_HTML": "Ongeldige zoekinstellingen",
  "INVALID_VIEW_ITEM_INVALID_PROPERTIES": "<code>{{fieldName}}</code> heeft ongeldige eigenschappen",
  "INVALID_VIEW_ITEM_INVALID_PROPERTY": "<code>{{fieldName}}</code> has an invalid property <code>{{property}}</code>",
  "INVALID_VIEW_ITEM_DATA_NAME": "<code>{{fieldName}}</code> heeft een ongeldige data name",
  "INVALID_VIEW_ITEM_WIDGET_UID": "<code>{{fieldName}}</code> widget is niet geïnstalleerd",
  "INVALID_VIEW_ITEM_VIEW_ID": "<code>{{fieldName}}</code> verwijst niet naar een bekende weergave",
  "INVALID_VIEW_ITEM_MAIL": "Ongeldige placeholder in de e-mail bij<code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_MAIL_DYNAMIC_RECIPIENTS": "Ongeldige dynamische ontvangers in e-mail bij <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_MAIL_STATIC_RECIPIENTS": "Ongeldige ontvanger in e-mail: <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_RULE": "Ongeldige regel met naam <code>{{ fieldName }}</code>",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_TITLE": "Gebruikersbeheer",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_USER_LABEL": "Gebruikers die toegang hebben",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_NO_USERS": "Geen gebruikers",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX": "gebruiker",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX_PLURAL": "gebruikers",
  "CUSTOMER_APPLICATIONS_DELETED_PREFIX": "Verwijderd",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_GROUP_LABEL": "Groepen die toegang hebben",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_NO_GROUPS": "Geen groepen",
  "FORM_USER_MANAGEMENT_DESCRIPTION": "Bepaal wie dit formulier kan invullen",
  "FOLDER_USER_MANAGEMENT_DESCRIPTION": "Bepaal wie formulieren kan toevoegen aan deze map",
  "CONTACT": "Contact",
  "ADDITIONAL_FEES_APPLY": "Extra kosten van toepassing",
  "CUSTOMER_CONTACT_VAT_NUMBER": "BTW",
  "CUSTOMER_CONTACT_COC_NUMBER": "KVK",
  "CUSTOMER_CONTACT_SUPPORT_TITLE": "Support",
  "CUSTOMER_CONTACT_SUPPORT_SUCCESS_MESSAGE": "Dank je wel voor je melding! We komen hier snel op terug.",
  "CUSTOMER_CONTACT_SUPPORT_ERROR_MESSAGE": "Je melding is niet verzonden. Neem gerust contact met ons op.",
  "BILLING_INFO": "Betaalinformatie",
  "CONTACT_INFO": "Contactinformatie",
  "INVOICES": "Facturen",
  "COPY_COLLECTION_TO_CUSTOMER_MODAL_TITLE": "Kopieer map naar klant",
  "COPY_COLLECTION_TO_CUSTOMER_MODAL_MESSAGE": "Kopieer een template map naar een van je klanten.",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_LABEL": "Account",
  "COPY_COLLECTION_TO_CUSTOMER_OPTIONS_LABEL": "Wil je de klantspecifieke inhoud kopiëren van deze map?",
  "COPY_COLLECTION_TO_CUSTOMER_OPTIONS_DATASOURCES": "Databronnen",
  "COPY_COLLECTION_TO_CUSTOMER_OPTIONS_RESOURCES": "Afbeeldingen",
  "COPY_COLLECTION_TO_CUSTOMER_NAME_LABEL": "Naam",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_PLACEHOLDER": "Selecteer account",
  "TOOLTIP_COPY_COLLECTION_TO_CUSTOMER_NAME": "Kies een naam voor de map die je kopieert naar een ander account.",
  "REMOVE_COLLECTION_MODAL_TITLE": "Verwijder Map",
  "REMOVE_COLLECTION_MODAL_MESSAGE": "De  map wordt verwijderd",
  "COPY_COLLECTION_MODAL_TITLE": "Kopieer Map",
  "COPY_COLLECTION_MODAL_MESSAGE": "De map wordt gekopieerd met een nieuwe naam",
  "COPY_COLLECTION_MODAL_NAME_LABEL": "Naam",
  "TOOLTIP_COPY_COLLECTION_MODAL_NAME": "De nieuwe mapnaam",
  "COLLECTION_REMOVE_MODAL_TITLE": "Map Verwijderen",
  "COLLECTION_REMOVE_MODAL_MESSAGE": "Weet je zeker dat je deze map wil verwijderen? Deze actie kun je niet ongedaan maken.",
  "COPY_FORM_MODAL_TITLE": "Formulier Kopiëren",
  "COPY_FORM_MODAL_MESSAGE": "Kies een map waar je het formulier naar toe wilt kopiëren. Je kunt de naam van het formulier aanpassen.",
  "COPY_FORM_MODAL_NAME_LABEL": "Naam",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_LABEL": "De nieuwe map",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_PLACEHOLDER": "Kies een  map waar je het formulier naar toe kopieert",
  "TOOLTIP_COPY_FORM_MODAL_NAME": "Je kunt de naam van de kopie aanpassen.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_COLLECTION": "De kopie wordt geplaatst in deze map.",
  "REMOVE_LIST_ITEM_MODAL_TITLE": "Verwijder Weergave",
  "REMOVE_LIST_ITEM_MODAL_MESSAGE": "Weet je zeker dat je deze weergave verijderen wil?",
  "SUPPORT_TITLE": "Support",
  "SUPPORT_QUESTION": "Heb je een vraag voor ons?",
  "SUPPORT_CONTACT_BY_EMAIL_MESSAGE": "Wil je een bug melden? Mis je een functie? Stuur een mail naar <a href=\"mailto:support@moreapp.com\">support@moreapp.com</a>. Beschrijf je vraag tot in detail, het liefste met de stappen die je volgt zodat wij je vraag kunnen nabootsen. Geef ook aan om welke gebruiker / functionaliteit je vraag gaat.",
  "SUPPORT_QUESTION_SUBTITLE": "Vul onderstaand formulier in om ons op de hoogte te stellen!",
  "SUPPORT_APPLICATION_QUESTION": "De naam van de map?",
  "SUPPORT_APPLICATION_PLACEHOLDER": "Mapnaam",
  "SUPPORT_FORM_QUESTION": "De naam van het formulier?",
  "SUPPORT_FORM_PLACEHOLDER": "Formuliernaam",
  "SUPPORT_DEVICE_QUESTION": "`Met wat voor een toestel werk je?",
  "SUPPORT_DEVICE_PLACEHOLDER": "Type / Softwareversie",
  "SUPPORT_DESCRIPTION_QUESTION": "Is er nog andere informstie die ons kan helpen?",
  "SUPPORT_DESCRIPTION_PLACEHOLDER": "Omschrijving",
  "SUPPORT_EMAIL_QUESTION": "Hoe kunnen we contact met jou opnemen?",
  "SUPPORT_EMAIL_PLACEHOLDER": "E-mailadres",
  "SUPPORT_SUCCESS_TITLE": "Bedankt!",
  "SUPPORT_SUCCESS_MESSAGE": "We hebben je melding ontvangen. Hier komen we snel op terug.",
  "APPLICATION_EDITOR_TITLE_PREFIX": "Bewerk de map: ",
  "APPLICATION_EDITOR_SETTINGS_TITLE": "Instellingen",
  "APPLICATION_EDITOR_SETTINGS_NAME_LABEL": "Naam",
  "APPLICATION_EDITOR_SETTINGS_NAME_PLACEHOLDER": "Geef de map een naam",
  "APPLICATION_EDITOR_SETTINGS_START_PAGE_LABEL": "Startpagina",
  "APPLICATION_EDITOR_SETTINGS_THEME_LABEL": "Thema",
  "APPLICATION_EDITOR_SETTINGS_IMAGE_LABEL": "Afbeelding",
  "APPLICATION_EDITOR_VIEWS_TITLE": "Weergaven",
  "APPLICATION_EDITOR_TO_LABEL": "Naar",
  "APPLICATION_EDITOR_CC_LABEL": "CC",
  "APPLICATION_EDITOR_BCC_LABEL": "BCC",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_TITLE": "Ga terug naar de eenvoudige modus",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_MESSAGE": "Weet je zeker dat je terug wilt naar de eenvoudige modus? Dit betekent dat je de gepersonaliseerde PDF verliest en teruggaat naar de standaard PDF.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_NAME_LABEL": "Naam",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_STATIC_RECIPIENTS_LABEL": "Een lijst van ontvangers, gescheiden door een komma",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_DYNAMIC_RECIPIENTS_LABEL": "Dynamische ontvangers",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_SUBJECT_LABEL": "Onderwerp",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_LABEL": "Van",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_PLACEHOLDER": "Afzender",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_LABEL": "Antwoord",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_PLACEHOLDER": "Stuur een antwoord naar dit adres",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_EMAIL_LABEL": "Afzender e-mail",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ADD_ATTACHMENTS_LABEL": "Reset pin bijlagen",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_LABEL": "Reset bijlage",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_TITLE": "Weet je zeker dat je de PDF wil resetten?",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_MESSAGE": "Als je de PDF reset, worden de aanpassingen die er gedaan zijn overschreven. De eenvoudige PDF bijlage wordt weer toegevoegd.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ALL_IMAGES": "Voeg afbeeldingen toe",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ATTACH_PDF": "Voeg een PDF toe met de naam:",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER": "Voeg een kop- en voettekst toe",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO": "Met logo:",
  "APPLICATION_EDITOR_NOTIFICATION_HIDE_NO_VALUES": "Verberg velden die  niet zijn ingevuld",
  "APPLICATION_EDITOR_NOTIFICATION_HORIZONTAL_ORIENTATION": "Gebruik een horizontale opzet voor je subformulier",
  "APPLICATION_EDITOR_NOTIFICATION_PLACEHOLDERS_LABEL": "Placeholders",
  "APPLICATION_EDITOR_NOTIFICATION_SHOW_MAP_LABEL": "Laat kaart zien",
  "APPLICATION_EDITOR_TOOLTIP_STATIC_RECIPIENTS": "Vul één of meerdere e-mailadressen in naar wie je het formulier wilt versturen, gescheiden door komma\\'s. <br><br><b>Voorbeeld</b><br>alice@example.com, bob@example.com",
  "${sys.user}": "gebruiker",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_TITLE": "Publiceren Mislukt",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_MESSAGE": "Het formulier is opgeslagen, maar kan vanwege fouten niet worden gepubliceerd.",
  "VIEW_EDITOR_FORM": "Formulier",
  "VIEW_EDITOR_EMAIL": "E-mail",
  "VIEW_EDITOR_EMAIL_SUBTITLE": "Verstuur een e-mail met PDF voor elke registratie",
  "VIEW_EDITOR_RULES": "Regels",
  "VIEW_EDITOR_RULES_SUBTITLE": "Verberg of toon velden onder bepaalde condities",
  "VIEW_EDITOR_SETTINGS": "Instellingen",
  "VIEW_EDITOR_SETTINGS_SUBTITLE": "Pas de uitstraling van je formulier aan",
  "VIEW_EDITOR_SETTINGS_SEARCH_SUBTITLE": "Maakt het voor gebruikers mogelijk om te zoeken naar registraties in de app, en dat te gebruiken als uitgangspunt voor een nieuwe registratie",
  "VIEW_EDITOR_WIDGETS": "Widgets",
  "VIEW_EDITOR_HOOKS": "Integraties",
  "VIEW_EDITOR_HOOKS_SUBTITLE": "Trigger een extern systeem na iedere registratie",
  "VIEW_EDITOR_PROPERTIES": "Properties",
  "VIEW_EDITOR_PROPERTIES_INVALID_DATA_NAME": "De data name is ongeldig",
  "VIEW_EDITOR_DATA_NAME": "Data name",
  "VIEW_EDITOR_DUPLICATE_DATA_NAME_WARNING": "Deze data name bestaat al.",
  "VIEW_EDITOR_RULES_ADD_BUTTON": "Voeg Regel toe",
  "VIEW_EDITOR_HOOKS_ADD_BUTTON": "Voeg integratie toe",
  "HOOK_SELECT_TITLE": "Selecteer integratie",
  "INTEGRATIONS_SUBTITLE": "Je kunt ook integreren door gebruik te maken van <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjExMTkxNg-webhooks' target='_blank'>Webhooks</a>, our <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjQ2NDA2-introduction' target='_blank'>API</a>, of <a href='https://zapier.com/apps/moreapp/integrations' target='_blank'>Zapier</a>",
  "VIEW_EDITOR_HOOKS_NO_CONFIG_MESSAGE": "Er is niets te configureren voor deze integratie",
  "VIEW_EDITOR_HOOKS_VALID_HOOK_MESSAGE": "Configuratie is succesvol gevalideerd",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_TITLE": "Verwijder integratie",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_MESSAGE": "Weet je zeker dat je deze integratie wilt verwijderen? Je kunt dit niet ongedaan maken.",
  "VIEW_EDITOR_HOOKS_NO_HOOKS": "Er zijn geen integraties beschikbaar om toe te voegen",
  "VIEW_EDITOR_NOT_VALIDATED_MESSAGE": "Configuratie is opgeslagen, maar kon op dit moment nog niet gevalideerd worden. Als het probleem aanhoudt, neem dan contact op met de auteur van deze integratie: {{author}}",
  "VIEW_EDITOR_HOOKS_GET_MORE_HOOKS": "Krijg meer integraties",
  "VIEW_EDITOR_HOOKS_UNAVAILABLE_TOOLTIP": "Deze integratie is onbeschikbaar sinds {{lastAvailableDate|date:'medium'}}. Je kunt de configuratie opslaan, maar deze wordt niet gevalideerd. Ook zal de integratie pas uitgevoerd worden als deze weer beschikbaar is.",
  "VIEW_EDITOR_RULE_NAME_LABEL": "Naam",
  "VIEW_EDITOR_RULE_CONDITION_LABEL": "Condities",
  "VIEW_EDITOR_RULE_OTHER_TYPES": "Andere types",
  "VIEW_EDITOR_RULE_USER": "Klantnaam",
  "VIEW_EDITOR_RULE_CHOOSE_FIELD": "Kies een veld",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_CONDITION": "Kies een conditie",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_VALUE": "Kies een waarde",
  "VIEW_EDITOR_RULE_CONDITION_STARTS_WITH": "Start met",
  "VIEW_EDITOR_RULE_CONDITION_ENDS_WITH": "Eindigt op",
  "VIEW_EDITOR_RULE_CONDITION_CONTAINS": "Bevat",
  "VIEW_EDITOR_RULE_CONDITION_GREATER_THAN": "Meer dan",
  "VIEW_EDITOR_RULE_CONDITION_LESS_THAN": "Minder dan",
  "VIEW_EDITOR_RULE_CONDITION_IS": "Is",
  "VIEW_EDITOR_RULE_CONDITION_HAS_VALUE": "Heeft waarde",
  "VIEW_EDITOR_RULE_CONDITION_VALUE_PLACEHOLDER": "waarde",
  "VIEW_EDITOR_RULE_ACTION_VALUE_PLACEHOLDER": "waarde",
  "VIEW_EDITOR_RULE_CONDITION_CURRENT_FORM_FIELDS": "Huidige velden",
  "VIEW_EDITOR_RULE_CONDITION_OTHER_FORM_FIELDS": "Andere velden",
  "VIEW_EDITOR_RULE_ACTION_LABEL": "Acties",
  "VIEW_EDITOR_RULE_ACTION_PREFIX": "Als",
  "VIEW_EDITOR_RULE_ACTION_POSTFIX": "condities gelden, voer het volgende uit",
  "VIEW_EDITOR_RULE_ACTION_CHOOSE_AN_ACTION_LABEL": " Kies een actie",
  "VIEW_EDITOR_VIEW_SETTINGS_TITLE": "Instellingen",
  "VIEW_EDITOR_SETTINGS_VIEW_NAME_LABEL": "Naam",
  "VIEW_EDITOR_SETTINGS_VIEW_DESCRIPTION_LABEL": "Omschrijving",
  "VIEW_EDITOR_SETTINGS_FORM_ICON_LABEL": "Icoon",
  "VIEW_EDITOR_SETTINGS_HIDDEN_IN_APP": "Verbergen in app",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL": "Hoofdformulier",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL_DESCRIPTION": "Dit formulier moet als hoofdformulier verschijnen",
  "VIEW_EDITOR_SETTINGS_INTERACTION_LABEL": "Upload interactie",
  "VIEW_EDITOR_SETTINGS_INTERACTION_IMMEDIATE_UPLOAD_LABEL": "Directe upload",
  "VIEW_EDITOR_SETTINGS_INTERACTION_MANUAL_UPLOAD": "Handmatige upload",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML": "Omschrijving in app",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_LABEL": "Opslaan functie",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_SAVE_AND_CLOSE_LABEL": "Opslaan en sluiten (standaard)",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_ALL_LABEL": "Opslaan en sluiten & Opslaan en nieuw",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_NO_SAVE_LABEL": "Niet opslaan (alleen lezen)",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_LABEL": "Zoekinstellingen",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ENABLED": "Ingeschakeld",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Toon alleen registraties van huidige gebruiker",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTERING_ENABLED": "Filter ingeschakeld",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FIELDS": "Velden",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_CHOOSE_FIELD": "Kies veld",
  "VIEW_EDITOR_SETTINGS_FORM_PLACEHOLDER": "Formulier",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML_LABEL": "Item markup",
  "ITEM_HTML_AVAILABLE_PARAMETERS": "Beschikbare parameters:",
  "VIEW_EDITOR_LOOKUP_VALUE_INVALID": "Deze waarde bestaat niet",
  "VIEW_EDITOR_OPTION_ID_LABEL": "Waarde",
  "VIEW_EDITOR_OPTION_EMAIL_LABEL": "Waarde in PDF",
  "VIEW_EDITOR_OPTION_VALUE_LABEL": "Waarde",
  "VIEW_EDITOR_OPTION_ADD_BUTTON": "Voeg toe",
  "VIEW_EDITOR_OPTIONS_USE_IMAGES_AS_LABELS": "Gebruik afbeeldingen als labels",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_IMAGE": "Selecteer afbeelding",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_RESOURCE": "Selecteer afbeelding",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_ICON": "Selecteer icoon",
  "VIEW_EDITOR_URL_MODAL_TITLE_ICON": "Standaard pin icoons",
  "VIEW_EDITOR_URL_BUTTON_TITLE_ICON": "Gebruik standaard icoon",
  "VIEW_EDITOR_ADD_TO_FAVORITES": "Voeg toe aan favorieten",
  "VIEW_EDITOR_ADVANCED_MODE": "Uitgebreide functie ",
  "VIEW_EDITOR_WIDGET_TYPE_DEFAULT": "Standaard",
  "VIEW_EDITOR_WIDGET_TYPE_INSTALLED": "Pro",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE": "Favorieten",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE_NO_WIDGETS": "Geen favorieten toegevoegd.",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_TITLE": "Voeg Favoriete Widget toe",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_NAME_LABEL": "Naam favoriet",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_NAME_LABEL": "Naam",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_ORIGINAL_VALUE_LABEL": "Originele waarde",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_VALUE_LABEL": "Favoriete waarde",
  "VIEW_EDITOR_ADD_FAVORITE_NO_CHANGES": "Er zijn geen veranderingen in de bestaande widget.",
  "VIEW_EDITOR_ADD_EMAIL_BUTTON": "Voeg E-mail toe",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_TITLE": "Verwijder Widget",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_MESSAGE": "Weet je zeker dat je deze favoriete widget wilt verwijderen?",
  "ERROR_SOMETHING_WENT_WRONG": "Er ging iets mis.",
  "ERROR_SOMETHING_WENT_WRONG_MESSAGE": "Probeer het later opnieuw. Als dit probleem zich blijft voordoen, neem dan contact met ons op",
  "ACCESS_DENIED_MESSAGE": "Toegang niet toegestaan",
  "ACCESS_DENIED_DESCRIPTION": "Dit Platform is voor Beheerders. Neem contact op met jouw Account Beheerder of log in op <a href='{{webclientUrl}}'>Web App</a> om formulieren in te vullen.",
  "ACCESS_DENIED_LOGOUT": "Uitloggen",
  "ACCESS_DENIED_TO_LOGIN": "Ga naar inloggen",
  "PAGE_NOT_FOUND_TITLE": "Pagina niet gevonden",
  "PAGE_NOT_FOUND_DESCRIPTION": "De pagina die je zocht kon niet worden gevonden",
  "PAGE_NOT_FOUND_PLATFORM_OVERVIEW_LINK": "Ga terug naar het Platform overzicht",
  "CUSTOMER_RESOURCES_TITLE": "Bronnen",
  "CUSTOMER_RESOURCES_UPLOAD_TYPE_TITLE": "Type",
  "CUSTOMER_RESOURCES_LIST_TITLE": "Gallerij",
  "CUSTOMER_RESOURCES_NAME_LABEL": "Naam",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_TITLE": "Upload als bestand",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_LABEL": "Bestand",
  "CUSTOMER_RESOURCES_UPLOAD_URL_TITLE": "Upload vanuit URL",
  "CUSTOMER_RESOURCES_UPLOAD_URL_LABEL": "URL",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_TITLE": "Verwijder bronnen",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_MESSAGE": "Weet je zeker dat je deze bron wilt verwijderen? Je kunt dit niet ongedaan maken",
  "RESOURCES_UPLOAD_FAILURE": "Upload Mislukt",
  "RESOURCES_UPLOAD_INVALID_FILE": "Het geselecteerde bestand is geen afbeelding.",
  "RESOURCES_UPLOAD_FILE_TOO_LARGE": "Het geselecteerde bestand overschrijdt het bestandslimiet van 5MB.",
  "REGISTRATIONS": "Registraties",
  "START": "Begin nu",
  "CONFIGURE": "Configureer",
  "UPGRADE": "Upgrade",
  "STEP": "Stap",
  "CREATED": "Gemaakt",
  "CREATE": "Maak",
  "READ": "Lees",
  "DELETE": "Verwijder",
  "REMOVE": "Verwijder",
  "SUBMIT": "Versturen",
  "CANCEL": "Annuleren",
  "CLOSE": "Sluiten",
  "SEND": "Verstuur",
  "SAVE": "Opslaan",
  "RENAME": "Hernoem",
  "RENAME_LINKED_SUBFORM_TITLE": "Hernoem gelinkt subformulier",
  "SAVE_PAYMENT_METHOD": "Bevestig en gebruik deze betaalmethode",
  "SELECTED_PAYMENT_METHOD": "Geselecteerde betaalmethode",
  "SELECT_OTHER_PAYMENT_METHOD": "Gebruik andere betaalmethode",
  "LOADING": "Laden",
  "SAVE_AND_CLOSE": "Opslaan & Sluiten",
  "SAVE_AND_PUBLISH": "Opslaan & Publiceren",
  "VALIDATE": "Valideren",
  "EDIT": "Bewerk",
  "UNDO": "Ongedaan maken",
  "BACK": "Terug",
  "NEXT": "Volgende",
  "PREVIOUS": "Vorige",
  "CLEAR": "Wissen",
  "SELECT": "Selecteer",
  "UPLOAD": "Upload",
  "BUSY_UPLOADING": "Bezig met uploaden...",
  "UPDATE": "Update",
  "ADD": "Voeg toe",
  "COPY": "Kopieer",
  "SHARE": "Delen",
  "ACTIONS": "Acties",
  "THEME": "Thema",
  "ON": "Aan",
  "OFF": "Uit",
  "OPTIONS": "Opties",
  "INSTALLED": "Geïnstalleerd",
  "EMPTY": "Leeg",
  "QUARTER_HOUR": "Elke 15 minuten",
  "HALF_HOUR": "Elke 30 minuten",
  "DAILY": "Elke dag",
  "WEEKLY": "Elke week",
  "HOURLY": "Elk uur",
  "API": "API",
  "NAME": "Naam",
  "EMAIL": "E-mail",
  "DATE": "Datum",
  "DUE_DATE": "Vervaldatum",
  "HOOKS": "Integraties",
  "NONE": "Geen",
  "NEVER": "Nooit  ",
  "USER": "Gebruiker",
  "GROUP": "Groep",
  "VIA_GROUP": "via groep(en)",
  "BETA": "beta",
  "LOCKED": "Locked",
  "URL": "URL",
  "FILE": "Bestand",
  "YES": "Ja",
  "NO": "Nee",
  "DEFAULT": "Standaard",
  "SEARCH": "Zoek",
  "DETAILS": "Details",
  "UNSAVED_CHANGES_TITLE": "Niet-opgeslagen wijzigingen",
  "UNAVAILABLE": "Onbereikbaar",
  "VERSION": "Versie",
  "PRICE": "Prijs",
  "FREE": "Gratis",
  "ADDRESS": "Adres",
  "ADDRESS_LINE_1": "Adres",
  "ADDRESS_LINE_1_PLACEHOLDER": "123 Main Street",
  "CITY": "Stad",
  "COUNTRY": "Land",
  "ZIP_CODE": "Postcode",
  "OK": "OK",
  "INTERNAL_ERROR": "Interne fout",
  "OPEN": "Open",
  "STALLED": "Geblokkeerd",
  "ERROR": "Mislukt",
  "SUCCESS": "Gelukt",
  "RETRY": "Opnieuw proberen",
  "SKIP": "Sla over",
  "IN_PROGRESS": "Bezig",
  "COMPLETED": "Afgerond",
  "DECLINED": "Geweigerd",
  "BLOCKED": "Eén van de integraties is gefaald",
  "NOT_VALIDATED": "Niet gevalideerd",
  "INVALID": "Ongeldig",
  "VALID": "Geldig",
  "VERIFIED": "Geverifieerd ",
  "UNVERIFIED": "Niet geverifieerd",
  "TYPE": "Type",
  "VALUE": "Waarde",
  "ONLINE": "Online",
  "OFFLINE": "Offline",
  "ADVANCED": "Uitgebreid",
  "PREVIEW": "Preview",
  "BY": "Door",
  "UNTIL": "Tot",
  "COUPON": "Coupon",
  "COUPON_ADD_ERROR": "Ongeldige coupon",
  "DISCOUNT": "Korting",
  "COUPON_REMOVE_TITLE": "Coupon verwijderen",
  "COUPON_REMOVE_MESSAGE": "Weet je zeker dat je deze coupon wil verwijderen?",
  "PERMISSIONS": "Rechten",
  "THIS_MIGHT_TAKE_SOME_TIME": "Dit kan even duren",
  "FIELD_ERROR_REQUIRED": "Dit veld is verplicht",
  "FIELD_ERROR_MIN_LENGTH": "Veld moet minimaal {{minLength}} karakters lang zijn",
  "FIELD_ERROR_MAX_LENGTH": "Veld is langer dan maximum {{maxLength}}",
  "FIELD_ERROR_MIN": "Waarde is lager dan minimum waarde {{min}}",
  "FIELD_ERROR_MAX": "Waarde is hoger dan maximum waarde {{max}}",
  "FIELD_ERROR_EMAIL": "Waarde is geen geldig e-mailadres",
  "BILLING_BANK_ACCOUNT_NUMBER": "Bankrekeningnummer (IBAN)",
  "BILLING_VAT_NUMBER": "BTW-nummer",
  "BILLING_VAT_NUMBER_CHECKING": "Controleren",
  "BILLING_VAT_NUMBER_VALID": "Geldig",
  "BILLING_VAT_NUMBER_APPLIED_PERCENTAGE": "BTW-percentage",
  "BILLING_VAT_NUMBER_INVALID": "Ongeldig",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH": "Het land komt niet overeen",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH_MESSAGE": "Het land van het btw-identificatienummer en het geselecteerde land, komen niet overeen. Los dit op, zodat wij de bestelling vervolgens kunnen verwerken.",
  "BILLING_COC_NUMBER": "KvK",
  "BILLING_EMAIL": "Factuur e-mailadres",
  "VAT_DISCLAIMER": "Prijzen zijn inclusief btw. Het btw-bedrag wordt getoond in het betalingsproces en komt op de factuur te staan.",
  "RECEIVE_NEWS_LETTER": "Nieuwsbrief",
  "RECEIVE_NEWS_LETTER_MESSAGE": "Ik wil de nieuwsbrief ontvangen",
  "TERMS_OF_CONDITION_DOWNLOAD": "Download de voorwaarden",
  "FORM_BUILDER_SUB_FORM_PANEL_TITLE": "Subformulier",
  "FORM_BUILDER_NEW_SUB_FORM_NAME": "Naam van nieuw formulier",
  "FORM_BUILDER_SUB_FORM_NAME": "Naam van formulier",
  "TOOLTIP_ADD_APPLICATION": "Een map bevat formulieren.<br><br>Elke map heeft zijn eigen instellingen, gebruikerbeheer en kan individueel gepubliceerd worden.",
  "TOOLTIP_ADD_APPLICATION_NAME": "Deze naam wordt getoond in de app.",
  "TOOLTIP_ADD_FORM": "Voeg een formulier toe om registraties te kunnen maken in de app.<br><br>Elk formulier heeft een aantal velden, regels en instellingen.",
  "TOOLTIP_ADD_FORM_NAME": "Deze naam wordt getoond in de app.",
  "TOOLTIP_COPY_FORM_NAME": "Gebruik eventueel een andere naam voor de kopie.",
  "TOOLTIP_COPY_FORM_DESTINATION": "De kopie wordt geplaatst in deze map.",
  "TOOLTIP_DELETE_FORM": "<b>Deze actie is onomkeerbaar</b>.<br>Onthoud dat de registraties bewaard blijven.",
  "TOOLTIP_COMMON_APPLICATION_INFO": "<a>Pak mij op</a> om de volgorde van de formulieren in de map te wijzigen.<br><br>",
  "TOOLTIP_APPLICATION_DELETED_MESSAGE": "De map is gearchiveerd. Herstel deze actie door te klikken op de knop <a>Ongedaan maken</a>.",
  "TOOLTIP_APPLICATION_NOT_PUBLISHED_MESSAGE": "De map is nog niet gepubliceerd.<br><br>Als je deze map in de app wilt zien, zul je de map eerst moeten <a>Publiceren</a>.",
  "TOOLTIP_APP_MANAGE_USERS": "Bepaal welke groepen en/of gebruikers deze map in de app kunnen zien.",
  "TOOLTIP_VIEW_SETTING_NAME": "Deze naam wordt in de app weergegeven.",
  "TOOLTIP_VIEW_SETTING_ICON": "Dit icoon wordt gebruikt als het formulier in een lijst wordt weergegeven",
  "TOOLTIP_VIEW_SETTING_IS_ROOT_VIEW": "Toon dit formulier in het <a>Formulieren</a> overzicht.",
  "TOOLTIP_VIEW_SETTING_INTERACTION": "Kies hoe en wanneer dit formulier wordt verstuurd:<br><br><a>Direct (standaard):</a><br>Als je het formulier opslaat, versturen we het direct (indien je internetverbinding hebt).<br><br><a>Handmatig:</a><br>Formulieren worden op het apparaat opgeslagen en kunnen handmatig verzonden worden vanuit het Registraties-scherm.",
  "TOOLTIP_VIEW_SETTING_SAVE_MODE": "Kies een modus:<br><br><a>Opslaan en sluiten (standaard):</a><br>Een eenvoudige <b>Verstuur</b>-knop, die meteen het formulier verstuurt en het formulier sluit (indien valide).<br><br><a>Opslaan en sluiten & Opslaan en nieuw:</a><br>Zowel de <b>Verzend</b> als <b>Verzend en nieuw</b> knop worden getoond, zodat je nieuwe formulieren kunt blijven invullen.<br><br><a>Niet opslaan:</a><br>Dit maakt het een 'Alleen-lezen' formulier.",
  "TOOLTIP_VIEW_SETTING_ITEM_HTML": "Dit bepaalt hoe iedere registratie, concept of taak wordt getoond in de app. Gebruik placeholders van velden waaraan je het makkelijk kunt herkennen.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS": "Vink dit aan om te kunnen zoeken op velden in dit formulier.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Vink dit aan om zoekresultaten te filteren op de ingelogde gebruiker.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_FILTER": "Vink dit aan om bepaalde velden uit het resultaat te filteren.",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTER_FIELD": "Neem de volgende waarden uit het resultaat niet over",
  "TOOLTIP_FORM_RULE_CONDITION": "Maak één of meerdere <a>Condities</a> die indien voldaan de <a>Acties</a> uitvoeren.<br><br>Zo kun je bijvoorbeeld niet-relevante velden verbergen in bepaalde gevallen.<br><br><b>Bijvoorbeeld:</b><br>Verberg <a>Partner</a> als <a>Burgelijke staat</a> is ingevuld als <a>'ongehuwd'</a>",
  "TOOLTIP_FORM_RULE_ACTION": "Maak één of meerdere <a>Acties</a> die uitgevoerd worden als wordt voldaan aan de <a>Condities</a>.",
  "TOOLTIP_PROPERTY_DATA_NAME": "Naam die wordt gebruikt als kolomnaam in het <a>Registratie overzicht</a>. Standaard wordt deze waarde gebaseerd op het <b>Label</b><br><br><b>Bijvoorbeeld:</b><br>Als je het label <a>'Hoe oud ben je?'</a> hebt, wordt de data name <a>hoeOudBenJe</a>. Dit zou je kunnen wijzigen naar <a>'leeftijd'</a>.",
  "TOOLTIP_ADD_DATA_SOURCE": "Nieuwe databron",
  "TOOLTIP_ADD_DATA_SOURCE_NAME": "Naam van de databron",
  "TOOLTIP_ADD_DATA_SOURCE_EXCEL_FILE": "Selecteer een Excel-bestand om te importeren. Zorg dat de extensie `.xlsx` is. `.xls` bestanden kunnen niet worden geïmporteerd.",
  "TOOLTIP_HOOK": "De configuratie van deze integratie voor dit specifieke formulier",
  "TOOLTIP_DIRECT_FORM_LINK": "Maak een unieke URL voor je formulier, zodat je het kunt delen met anderen.<br><br>Met deze URL kunnen mensen zonder account je formulier invullen.",
  "TOOLTIP_SHARE_FORM_MARKETPLACE": "Deel je formulier op onze Marketplace, zodat anders het als template kunnen gebruiken.",
  "RULE_HIDDEN": "Verborgen",
  "RULE_VISIBLE": "Zichtbaar",
  "RULE_GETS_VALUE": "Krijgt waarde",
  "WIDGET_LICENSE_INVALID": "Ongeldig kenteken",
  "WIDGET_LICENSE_BRAND": "Merk",
  "WIDGET_LICENSE_TYPE": "Type",
  "WIDGET_LICENSE_POWER": "Vermogen",
  "WIDGET_LICENSE_APK_EXPIRATION_DATE": "APK",
  "WIDGET_LICENSE_FIRST_INTERNATIONAL_ADMISSION": "Eerste toelating",
  "WIDGET_LICENSE_EURO_CLASSIFICATION": "Euro class",
  "SEGMENT": "Sector",
  "SEGMENT_AUTOMOTIVE": "Automotive",
  "SEGMENT_CONSTRUCTION": "Bouw",
  "SEGMENT_FACILITY": "Facilitair",
  "SEGMENT_FINANCIAL": "Financieel",
  "SEGMENT_TRADE_AND_INDUSTRY": "Handel en industrie",
  "SEGMENT_GOVERNMENT": "Overheid",
  "SEGMENT_HEALTH_CARE": "Gezondheidszorg",
  "SEGMENT_INSTALLATION": "Installatie",
  "SEGMENT_OTHER": "Anders",
  "GENERIC": "Algemeen",
  "FACILITY": "Facilitair",
  "CONSTRUCTION": "Bouw",
  "SALES": "Sales",
  "HR": "Personeelszaken",
  "AUTOMOTIVE": "Automotive",
  "SAFETY": "Veiligheid",
  "LOGISTICS": "Logistiek",
  "INSTALLATION": "Installatie",
  "MAINTENANCE": "Service & Onderhoud",
  "SECURITY": "Veiligheid",
  "REAL_ESTATE": "Vastgoed",
  "HEALTHCARE": "Zorg",
  "AGRICULTURE": "Landbouw",
  "INDUSTRY": "Industrie",
  "INSUFFICIENT_PERMISSIONS_TITLE": "Geen toestemming",
  "INSUFFICIENT_PERMISSIONS_MESSAGE": "Je bent ingelogd in het platform. Dit is alleen toegankelijk voor administrators. Als je toegang nodig hebt, neem dan contact op met je administrator. Als je alleen formulieren wilt invullen, installeer dan de app op je telefoon of tablet.",
  "FORM_SUBMISSION_SERVER_ERROR": "Het formulier kon niet worden verzonden. Dit kan een serverprobleem zijn. Probeer het later nog eens.",
  "UNKNOWN_ERROR": "Er is een fout opgetreden",
  "ALL": "alle",
  "ANY": "één of meer",
  "GLOBAL_ERRORS_TITLE": "Validatiefouten",
  "USE_TEMPLATE": "Gebruik sjabloon",
  "LANGUAGE_EN": "Engels",
  "LANGUAGE_NL": "Nederlands",
  "LANGUAGE_DE": "Duits",
  "LANGUAGE_ES": "Spaans",
  "LANGUAGE_RU": "Russisch",
  "LANGUAGE_PT": "Portugees",
  "LANGUAGE_FR": "Frans",
  "LANGUAGE_IT": "Italiaans",
  "LANGUAGE_CA": "Catalaans",
  "UNSAVED_CHANGES": "Er zijn niet-opgeslagen wijzigen die je verliest als je de pagina verlaat",
  "CHANGE_FILE": "Verander bestand",
  "AUTHENTICATED": "Aangemeld",
  "LANGUAGE": "Taal",
  "TAGS": "Tags",
  "SELECT_TAGS": "Selecteer tags",
  "SELECT_ALL": "Selecteer alles",
  "SEARCH_TEMPLATES": "Zoek templates",
  "CUSTOMER": "Klant",
  "PARTNER": "Partner",
  "CUSTOM": "Custom",
  "AMOUNT": "Aantal",
  "PHONE": "Telefoonnummer",
  "REGION": "Regio",
  "STATUS": "Status",
  "ACTIVE": "Actief",
  "INACTIVE": "Inactief",
  "DISABLED": "Inactief",
  "ACCOUNT": "Account",
  "DOCUMENTATION": "Documentatie",
  "COMPANY_NAME": "Bedrijfsnaam",
  "COMPANY": "Bedrijf",
  "SHOW_MORE": "Toon meer",
  "WEBSITE": "Website",
  "CLICK_HERE": "Klik hier",
  "MAIL_NOTIFICATION": "E-mail",
  "CHECKED": "Aangevinkt",
  "UNCHECKED": "Niet aangevinkt",
  "ACCOUNT_NAME": "Bedrijf",
  "ACCOUNT_NAME_PLACEHOLDER": "Bijvoorbeeld: Bedrijf BV of Jan Jansen",
  "FEATURE_HOOKS": "Integraties",
  "FEATURE_HOOKS_MESSAGE": "<h4>Integraties worden gebruikt om gegevens te verwerken na het verzenden van formulieren</h4><p>Implementeer een workflow, verstuur bestanden naar OneDrive, verstuur data naar Google Sheets of naar Word</p><p>Deze functionaliteit is beschikbaar vanaf het <b>Branch</b> plan.</p>",
  "FEATURE_MANUAL_DATASOURCES": "Data import",
  "FEATURE_MANUAL_DATASOURCES_MESSAGE": "<h4>Importeer data met Excel, Google Spreadsheets of een URL (JSON)</h4><p>Deze functionaliteit is beschikbaar vanaf het <b>Leaf</b> plan.</p>",
  "FEATURE_AUTOMATIC_DATASOURCES": "Automatische data import",
  "FEATURE_AUTOMATIC_DATASOURCES_MESSAGE": "<h4>Importeer data automatisch door het toevoegen van een nieuwe regel aan de Google Sheet of URL</h4><p>Deze functionaliteit is beschikbaar vanaf het <b>Branch</b> plan.</p>",
  "FEATUER_ADVANCED_PDF": "Gepersonaliseerde PDF",
  "FEATUER_ADVANCED_PDF_MESSAGE": "<h4>Ontwerp het PDF-rapport met HTML</h4><p>Deze functionaliteit is beschikbaar vanaf het <b>Branch</b> plan.</p>",
  "FEATURE_ADVANCED_USER_MANAGEMENT": "Rollen & rechten",
  "FEATURE_ADVANCED_USER_MANAGEMENT_MESSAGE": "<h4>Beheer rechten van groepen gebruikers in het Platform</h4><p>Deze functionaliteit is beschikbaar vanaf het <b>Tree</b> plan.</p>",
  "FEATURE_PDF_LOGO": "Jouw logo op de PDF",
  "FEATURE_PDF_LOGO_MESSAGE": "<h4>Vervang het MoreApp logo voor jouw logo</h4><p>Deze functionaliteit is beschikbaar vanaf het <b>Leaf</b> plan.</p>",
  "FEATURE_MULTIPLE_EMAIL": "Meerdere e-mails",
  "FEATURE_MULTIPLE_EMAIL_MESSAGE": "<h4>Voeg meerdere e-mails toe aan één formulier</h4><p>Deze functionaliteit is beschikbaar vanaf het <b>Leaf</b> plan.</p>",
  "FEATURE_WIDGETS": "Pro widgets",
  "FEATURE_WIDGETS_MESSAGE": "<h4>Ga aan de slag met meer widgets, zoals de Catalogue-, Calculation- en Rating-widgets</h4><p>Deze functionaliteit is beschikbaar vanaf het <b>Leaf</b> plan.</p>",
  "FEATURE_SEARCH": "Zoekfunctie in app",
  "FEATURE_SEARCH_MESSAGE": "<h4>Met de zoekfunctie is het zoeken naar ingevulde formulieren eenvoudig</h4><p>Met de zoekfunctie kun je eerder verstuurde registraties ophalen en gebruiken voor een nieuwe registratie</p><p>Deze functionaliteit is beschikbaar vanaf het <b>Leaf</b> plan.</p>",
  "FEATURE_THEMES": "Kleurenthema's",
  "FEATURE_THEMES_MESSAGE": "<h4>Vervang de MoreApp kleur in de formulieren voor jouw eigen kleur</h4><p>Deze functionaliteit is beschikbaar vanaf het <b>Tree</b> plan.</p>",
  "FEATURE_CUSTOM_TEMPLATES": "Eigen sjablonen",
  "FEATURE_CUSTOM_TEMPLATES_MESSAGE": "<h4>Maak gepersonaliseerde sjablonen en bespaar tijd bij het maken van nieuwe formulieren.</h4><p>Deze functionaliteit is beschikbaar vanaf het <b>Tree</b> plan.</p>",
  "FEATURE_WEBHOOKS": "Webhooks",
  "FEATURE_WEBHOOKS_MESSAGE": "<h4>Gebruik webhooks om te luisteren naar gebeurtenissen in je account.</h4><p>Op elke gebeurtenis sturen we een bericht naar de door jou geconfigureerde URL, zodat je daar actie op kunt ondernemen. Hiermee kun je complexe workflows implementeren.</p><p><b>Voorbeeld</b>: configureer een webhook die afgevuurd wordt op elk verzonden formulier, om de data uit het formulier in je eigen database op te slaan.</p><br><p>Deze functionaliteit is beschikbaar vanaf het <b>Branch</b> plan.</p>",
  "SWITCH_PLAN_CTA": "Selecteer een Abonnement",
  "SWITCH_PLAN_CONTACT_OWNER": "Contact Account Beheerder",
  "SWITCH_PLAN_CONTACT_OWNER_MAIL_SUBJECT": "Aanvraag om ons MoreApp account te upgraden om gebruik te maken van de feature \"{{feature}}\"",
  "SERVICE_ACCOUNTS_SELECT_PLACEHOLDER": "Kies een service account...",
  "SERVICE_ACCOUNTS_NEW_LINK": "Verbind",
  "SERVICE_ACCOUNTS_NEW_TEXT": "een nieuw service account",
  "SERVICE_ACCOUNTS_NON_FOUND_PROVIDER": "Geen '{{provider}}' service accounts geconfigureerd.",
  "FRESHCHAT_CONTENT_HEADERS_CHAT": "Chat met ons",
  "FRESHCHAT_CONTENT_HEADERS_FAQ_THANK_YOU": "Bedankt voor jouw feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_QUESTION": "Ben je naar tevredenheid geïnformeerd?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_YES_QUESTION": "Hoe beoordeel je jouw ervaring?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_NO_QUESTION": "Wat kunnen wij verbeteren?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_THANKYOU": "Bedankt voor jouw feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_RATE_HERE": "Vul hier jouw beoordeling in!",
  "FRESHCHAT_CONTENT_PLACEHOLDERS_CSAT_REPLY": "Voer hier jouw opmerkingen in",
  "ADD_USER_OR_GROUP": "Voeg gebruiker of groep toe",
  "ADD_USER_TO_GROUP": "Voeg gebruiker toe",
  "ADD_USER_TO_GROUP_PLACEHOLDER": "Kies één of meerdere gebruikers",
  "SELECT_ROLE": "Kies rol",
  "ALL_USERS_ADDED_TO_GROUP": "Je hebt alle gebruikers al aan deze groep toegevoegd",
  "WHATS_NEW": "Wat is er nieuw?",
  "WIDGET_LABEL": "Label",
  "WIDGET_LABEL_DESCRIPTION": "Gebruik de <b>Label</b>-widget om statische tekst aan jouw <a>formulier</a> toe te voegen.<br><br><b>Opmerking:</b><br>Dit is geen onderdeel van de <a>registratie data</a>.",
  "WIDGET_TEXT": "Text",
  "WIDGET_TEXT_DESCRIPTION": "Gebruik de <b>Tekst</b>-widget om een enkele regel tekst in te vullen.",
  "WIDGET_TEXT_AREA": "Text Area",
  "WIDGET_TEXT_AREA_DESCRIPTION": "Gebruik de <b>Text Area</b>-widget om volledige zinnen in te vullen.",
  "WIDGET_NUMBER": "Number",
  "WIDGET_NUMBER_DESCRIPTION": "Gebruik de <b>Number</b>-widget om een getal in te vullen.",
  "WIDGET_RADIO": "Radio",
  "WIDGET_RADIO_DESCRIPTION": "Gebruik de <b>Radio</b>-widget om één van de vooraf gedefinieerde waarden te kiezen.<br><br><b>Bijvoorbeeld:</b><br>Man / Vrouw.",
  "WIDGET_CHECKBOX": "Checkbox",
  "WIDGET_CHECKBOX_DESCRIPTION": "Gebruik de <b>Checkbox</b>-widget om een waarde als True of False te markeren.",
  "WIDGET_PHOTO": "Photo",
  "WIDGET_PHOTO_DESCRIPTION": "Gebruik de <b>Photo</b>-widget om een foto te maken met de <a>camera</a> van het apparaat of toe te voegen vanuit de <a>bibliotheek</a>.",
  "WIDGET_SIGNATURE": "Signature",
  "WIDGET_SIGNATURE_DESCRIPTION": "Gebruik de <b>Signature</b>-widget om een handtekening te plaatsen.",
  "WIDGET_HEADER": "Header",
  "WIDGET_HEADER_DESCRIPTION": "Gebruik de <b>Header</b>-widget om koppen aan het formulier toe te voegen.<br><br><b>Opmerking:</b><br>Dit is geen onderdeel van de <a>registratie data</a>.",
  "WIDGET_DATE": "Date",
  "WIDGET_DATE_DESCRIPTION": "Gebruik de <b>Date</b>-widget om een datum in te vullen.",
  "WIDGET_DATETIME": "Date Time",
  "WIDGET_DATETIME_DESCRIPTION": "Gebruik de <b>Date Time</b>-widget een datum en tijd in te vullen.",
  "WIDGET_TIME": "Time",
  "WIDGET_TIME_DESCRIPTION": "Gebruik de <b>Time</b>-widget om een tijd in te vullen.",
  "WIDGET_EMAIL": "Email",
  "WIDGET_EMAIL_DESCRIPTION": "Gebruik de <b>Email</b>-widget om een geldig e-mailadres in te vullen.<br><br><b>Opmerking:</b><br>Dit veld kan worden gebruikt als <a>dynamische ontvanger</a> in de <a>E-mail tab</a>.",
  "WIDGET_PHONE": "Phone",
  "WIDGET_PHONE_DESCRIPTION": "Gebruik de <b>Phone</b>-widget om een geldig telefoonnummer in te vullen.",
  "WIDGET_LOOKUP": "Lookup",
  "WIDGET_LOOKUP_DESCRIPTION": "Gebruik de <b>Lookup</b>-widget om een (of meer) vooraf gedefinieerde waarden te kiezen.",
  "WIDGET_LIST_ITEM": "List Item",
  "WIDGET_LIST_ITEM_DESCRIPTION": "Use the <b>List Item</b> widget to create a link to another <a>form</a>.",
  "WIDGET_SUBFORM": "Subform",
  "WIDGET_SUBFORM_DESCRIPTION": "Gebruik de <b>Subform</b>-widget om <a>dezelfde widgets meerdere keren</a> toe te voegen aan het formulier.<br><br><b>Bijvoorbeeld:</b><br>Voeg meerdere <a>gebruikte materialen, gewerkte uren, foto's van bouwdelen</a> toe aan een <a>registratie</a>. Het is ook mogelijk om subformulieren te koppelen en te werken met sub- in subformulieren.",
  "WIDGET_BARCODE": "Barcode",
  "WIDGET_BARCODE_DESCRIPTION": "Gebruik de <b>Barcode</b>-widget om een barcode te scannen met de <a>camera</a> van het apparaat.<br><br><b>Opmerking:</b><br>De widget ondersteunt QR, UPC, EAN, CODE_39 en meer.",
  "WIDGET_SLIDER": "Slider",
  "WIDGET_SLIDER_DESCRIPTION": "Gebruik de <b>Slider</b>-widget om een schuif te slepen en zo een ​​getal tussen de gedefinieerde min en max waarden te kiezen.",
  "WIDGET_HTML": "HTML",
  "WIDGET_HTML_DESCRIPTION": "Gebruik de <b>HTML</b>-widget om opmaak aan het formulier toe te voegen. (Alleen voor technische gebruikers)",
  "WIDGET_IMAGE": "Image",
  "WIDGET_IMAGE_DESCRIPTION": "Gebruik de <b>Image</b>-widget om een ​​afbeelding in het <a>formulier</a> te tonen.<br><br><b>Opmerking:</b><br>Dit is geen onderdeel van de <a>registratie data</a>.",
  "WIDGET_SEARCH": "Search",
  "WIDGET_SEARCH_DESCRIPTION": "Gebruik de <b>Search</b>-widget in combinatie met databronnen zodat je geïmporteerde data kunt selecteren. Zoals klant-, product- en werknemersinformatie.",
  "WIDGET_RDW": "License Plate (NL)",
  "WIDGET_RDW_DESCRIPTION": "Gebruik de <b>License Plate (NL)</b>-widget om een Nederlands kenteken in te voeren. De widget toont aanvullende informatie over het voertuig. <b>Opmerking:</b> Internetverbinding is vereist om de aanvullende informatie te tonen.",
  "WIDGET_PIN": "Pin",
  "WIDGET_PIN_DESCRIPTION": "Gebruik de <b>Pin</b>-widget om een of meer pinnen aan een afbeelding/kaart toe te voegen. Iedere pin die je plaatst, opent een <a>subformulier</a> om aanvullende informatie over de pin toe te voegen.",
  "WIDGET_HELP": "Help",
  "WIDGET_HELP_DESCRIPTION": "Met de Help-widget kun je aanvullende informatie toevoegen aan iedere widget. Als je op de Help-widget klikt, wordt er een pop-up weergegeven met de aanvullende informatie.",
  "WIDGET_RATING": "Rating",
  "WIDGET_RATING_DESCRIPTION": "Met de Rating-widget kun je een bepaald onderwerp beoordelen met een aantal sterren. Je kunt een schaal tussen twee en tien configureren.",
  "WIDGET_CATALOGUE": "Catalogue",
  "WIDGET_CATALOGUE_DESCRIPTION": "Maak je eigen bestel-app met de Catalogue-widget. Vul een winkelwagentje met producten. Gebruik een databron met de volgende vereiste kolommen: id, name, priceExVat, description. Optioneel: photo, thumbnail en vatRate. Photo en thumbnail zijn URL's, die alleen worden weergegeven als je apparaat verbinding met het internet heeft. Je kunt zoveel andere kolommen aan de databron toevoegen als je wil. Deze zijn zichtbaar in het product details scherm.",
  "WIDGET_SEARCH_WITH_GPS": "Search with GPS",
  "WIDGET_SEARCH_WITH_GPS_DESCRIPTION": "Search with GPS location",
  "WIDGET_TIME_DIFFERENCE": "Time Difference",
  "WIDGET_TIME_DIFFERENCE_DESCRIPTION": "Deze widget berekent het verschil tussen twee Time-widgets of Date Time-widgets.",
  "WIDGET_PAYMENT": "Payment",
  "WIDGET_PAYMENT_DESCRIPTION": "Lets the user make an in-app purchase to pay for the form.",
  "WIDGET_STOPWATCH": "Stopwatch",
  "WIDGET_STOPWATCH_DESCRIPTION": "Gebruik de Stopwatch-widget om de tijd in je formulier bij te houden. Het resultaat van deze widget is bijvoorbeeld 00:16.47.",
  "WIDGET_SMILEY": "Smiley",
  "WIDGET_SMILEY_DESCRIPTION": "Gebruik de Smiley-widget om een beoordeling te geven op basis van smileys.",
  "WIDGET_CURRENT_LOCATION": "Current Location",
  "WIDGET_CURRENT_LOCATION_DESCRIPTION": "Gebruik de Current Location-widget om automatisch de huidige locatie met behulp van GPS op te halen. Het resultaat ziet er uit als volgt: 'Museumstraat 1, 1071 XX Amsterdam, Nederland'. Je kunt het resultaat altijd handmatig aanpassen, mocht het niet helemaal kloppen.",
  "WIDGET_DRAWING": "Drawing",
  "WIDGET_DRAWING_DESCRIPTION": "Met deze widget kun je tekeningen, lijnen en teksten aan een foto toevoegen.",
  "WIDGET_VIDEO": "Video",
  "WIDGET_VIDEO_DESCRIPTION": "Met deze widget kun je een video opnemen of toevoegen. De widget resulteert in een URL in jouw PDF-bestand waarmee je de video kunt downloaden.",
  "WIDGET_ZIPCODE": "Zip Code",
  "WIDGET_ZIPCODE_DESCRIPTION": "De Zip Code-widget toont adresgegevens van een ingevoerde postcode.",
  "WIDGET_IBAN": "IBAN",
  "WIDGET_IBAN_DESCRIPTION": "De IBAN-widget valideert IBAN-nummers.",
  "WIDGET_DRAWING_FREE": "Drawing Free",
  "WIDGET_DRAWING_FREE_DESCRIPTION": "A copy of the Drawing widget, but free. For the customers of the Blauwdruk partner.",
  "WIDGET_CALCULATION": "Calculation",
  "WIDGET_CALCULATION_DESCRIPTION": "Met de Calculation-widget kun je meerdere widgets optellen, aftrekken, vermenigvuldigen en delen.",
  "WIDGET_POSTCODE_NL": "Zip Code (NL)",
  "WIDGET_POSTCODE_NL_DESCRIPTION": "Deze widget werkt alleen voor Nederlandse postcodes! Met de Zip Code (NL)-widget haal je adresgegevens op aan de hand van een postcode en huisnummer. Je hebt een internetverbinding nodig om de gegevens op te halen. Als je offline bent, kun je de gegevens handmatig toevoegen.",
  "WIDGET_ADVANCED_SEARCH": "Advanced Search",
  "WIDGET_ADVANCED_SEARCH_DESCRIPTION": "Using the Advanced Search widget you can color your search results in the app. You can configure which color should be used when a search result matches a query.You can define colors using descriptors (like ‘red' or ‘green') or using hexadecimal color codes (like #1dc4c2)",
  "WIDGET_FILE": "File",
  "WIDGET_FILE_DESCRIPTION": "Met deze widget kun je bestanden aan je formulier toevoegen. De widget resulteert in een URL in het PDF-bestand om de bestanden te downloaden.",
  "WIDGET_LOCATION": "Location",
  "WIDGET_LOCATION_DESCRIPTION": "Met deze widget kun je een locatie selecteren op een kaart. \n\nHet resultaat kan altijd handmatig aangepast worden, mocht dit nodig zijn.",
  "WIDGET_PROPERTY_PIN_NAME": "Naam",
  "WIDGET_PROPERTY_PIN_FORM": "Formulier",
  "WIDGET_PROPERTY_PIN_ICON": "Icoon",
  "WIDGET_PROPERTY_PIN_ITEM_MARKUP": "Samenvatting in de app",
  "WIDGET_PROPERTY_PIN_TRACKING_ID": "Tracking Id",
  "WIDGET_PROPERTY_LABEL": "Label",
  "WIDGET_PROPERTY_LABEL_DESCRIPTION": "De naam die boven de widget wordt weergegeven",
  "WIDGET_PROPERTY_OPERATOR": "Bewerkingen",
  "WIDGET_PROPERTY_OPERATOR_DESCRIPTION": "Bewerking voor de berekening.",
  "WIDGET_PROPERTY_CALCULATION_TERMS": "Berekening voorwaarden",
  "WIDGET_PROPERTY_CALCULATION_TERMS_DESCRIPTION": "Alle velden die in de berekening moeten worden opgenomen.",
  "WIDGET_PROPERTY_INITIAL_VALUE": "Beginwaarde",
  "WIDGET_PROPERTY_INITIAL_VALUE_DESCRIPTION": "Beginwaarde voor de berekening.",
  "WIDGET_PROPERTY_DECIMAL_PRECISION": "Decimale precisie",
  "WIDGET_PROPERTY_DECIMAL_PRECISION_DESCRIPTION": "Het aantal cijfers dat de berekening heeft.",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM": "Geen waarde met missende term",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM_DESCRIPTION": "Widget zou geen waarde moeten produceren wanneer een van de termen ongeldig is (D.w.z. geen nummer).",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE": "Catalogue databron",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE_DESCRIPTION": "Selecteer een databron om te gebruiken voor de catalogue. Zorg ervoor dat de databron voldoet aan de vereisten.",
  "WIDGET_PROPERTY_CURRENCY": "Valuta",
  "WIDGET_PROPERTY_CURRENCY_DESCRIPTION": "Bepaal welke valuta moet worden gebruikt, maar alleen als de waarde een prijs is",
  "WIDGET_PROPERTY_VAT_PERCENTAGE": "BTW percentage",
  "WIDGET_PROPERTY_VAT_PERCENTAGE_DESCRIPTION": "Het btw-tarief dat wordt toegepast op alle catalogusitems.",
  "WIDGET_PROPERTY_SHOW_PRICES": "Prijzen weergeven",
  "WIDGET_PROPERTY_SHOW_PRICES_DESCRIPTION": "Vink dit aan als je eenheids- en totaalprijzen weer wil geven in de app en PDF.",
  "WIDGET_PROPERTY_SHOW_VAT": "Toon BTW",
  "WIDGET_PROPERTY_SHOW_VAT_DESCRIPTION": "Vink dit aan om de btw-tarieven in de app en PDF weer te geven.",
  "WIDGET_PROPERTY_ALLOW_REMARKS": "Opmerkingen toestaan",
  "WIDGET_PROPERTY_ALLOW_REMARKS_DESCRIPTION": "Gebruikers kunnen een opmerking toevoegen aan een item.",
  "WIDGET_PROPERTY_RATE_ICON": "Rate icon",
  "WIDGET_PROPERTY_RATE_ICON_DESCRIPTION": "Select which icon is used for rating, e.g. hearts",
  "WIDGET_PROPERTY_RATE_SCALE": "Schaal",
  "WIDGET_PROPERTY_RATE_SCALE_DESCRIPTION": "Het aantal weergegeven pictogrammen.",
  "WIDGET_PROPERTY_TITLE": "Titel",
  "WIDGET_PROPERTY_TITLE_DESCRIPTION": "De titel van de gegeven uitleg.",
  "WIDGET_PROPERTY_EXPLANATION": "Uitleg",
  "WIDGET_PROPERTY_EXPLANATION_DESCRIPTION": "Voer hier de uitleg in.",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT": "Gebruik de help knop tekst",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT_DESCRIPTION": "Vink aan als je wil dat er tekst wordt weergegeven naast het vraagteken.",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT": "Help knop tekst",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT_DESCRIPTION": "Deze tekst wordt weergegeven naast het vraagteken.",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE": "De berekende waarde is een prijs",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE_DESCRIPTION": "Toon het resultaat als een prijs.",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS": "Negeer verborgen widgets",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS_DESCRIPTION": "Met deze instelling kun je verborgen widgets negeren bij het uitvoeren van berekeningen. Het is beschikbaar vanaf versie 7.0.32.",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER": "Gebruik de barcodescanner",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER_DESCRIPTION": "Vink deze optie aan om te kunnen zoeken naar een gescande barcode.",
  "WIDGET_PROPERTY_DEFAULT_VALUE": "Standaardwaarde",
  "WIDGET_PROPERTY_DEFAULT_VALUE_DESCRIPTION": "Deze waarde is al vooraf gedefinieerd.",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY": "Standaard Zoekopdracht",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY_DESCRIPTION": "Bij het openen van de zoekfunctie wordt deze vooraf gedefinieerde zoekopdracht gebruikt.",
  "WIDGET_PROPERTY_LABEL_TEXT": "Label",
  "WIDGET_PROPERTY_START_TIME": "Starttijd",
  "WIDGET_PROPERTY_START_TIME_DESCRIPTION": "Begintijd te gebruiken in de berekening.",
  "WIDGET_PROPERTY_END_TIME": "Eindtijd",
  "WIDGET_PROPERTY_END_TIME_DESCRIPTION": "Eindtijd te gebruiken in de berekening.",
  "WIDGET_PROPERTY_BACKGROUND_IMAGE": "Achtergrond Afbeelding",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS": "Maximale duur in seconden",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS_DESCRIPTION": "De maximale duur van de video in seconden.",
  "WIDGET_PROPERTY_VIDEO_QUALITY": "Video Kwaliteit",
  "WIDGET_PROPERTY_VIDEO_QUALITY_DESCRIPTION": "Kies de gewenste videokwaliteit.",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE": "Bestandstype",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE_DESCRIPTION": "Kies het bestandstype dat kan worden geüpload.",
  "WIDGET_PROPERTY_VALUE": "Waarde",
  "WIDGET_PROPERTY_PLACEHOLDER": "Placeholder",
  "WIDGET_PROPERTY_PLACEHOLDER_DESCRIPTION": "Het voorbeeld wat wordt weergegeven als het veld leeg is en wordt verwijderd als het is gevuld.",
  "WIDGET_PROPERTY_MAX_LENGTH": "Max lengte",
  "WIDGET_PROPERTY_MAX_LENGTH_DESCRIPTION": "Maximaal aantal tekens. De app toont een validatiefout als de waarde hoger is.",
  "WIDGET_PROPERTY_MIN_LENGTH": "Min lengte",
  "WIDGET_PROPERTY_MIN_LENGTH_DESCRIPTION": "Minimaal aantal tekens. De app toont een validatiefout als de waarde te kort is.",
  "WIDGET_PROPERTY_MINIMUM_VALUE": "Minimale waarde",
  "WIDGET_PROPERTY_MINIMUM_VALUE_DESCRIPTION": "De app staat het kiezen van een waarde lager dan deze niet toe.",
  "WIDGET_PROPERTY_MAXIMUM_VALUE": "Maximale waarde",
  "WIDGET_PROPERTY_MAXIMUM_VALUE_DESCRIPTION": "De app staat het niet toe om een waarde te kiezen die hoger is.",
  "WIDGET_PROPERTY_USE_IMAGES_AS_LABELS": "Gebruik afbeeldingen als labels",
  "WIDGET_PROPERTY_OPTIONS": "Opties",
  "WIDGET_PROPERTY_OPTIONS_DESCRIPTION": "Voer de opties in die je wil tonen in de app.",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT": "Verticale uitlijning",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT_DESCRIPTION": "Vink aan om de opties verticaal uit te lijnen in plaats van horizontaal.",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT": "Standaard aangevinkt",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT_DESCRIPTION": "Dit veld is aangevinkt bij het openen van een nieuw formulier.",
  "WIDGET_PROPERTY_PHOTO_QUALITY": "Foto kwaliteit",
  "WIDGET_PROPERTY_PHOTO_QUALITY_DESCRIPTION": "Kies de gewenste fotokwaliteit.<br><br><a>Beste mogelijk (aanbevolen)</a><br>Geen verkleining, geen compressie. Kan problemen veroorzaken bij het verzenden van e-mails met veel foto's.<br><br><a>Snelle upload</a><br>Verkleinen tot max. 1024x1024, 75% gecomprimeerd. Kan geheugenproblemen veroorzaken op low-end apparaten.<br><br><a>Hoge kwaliteit</a><br>Verkleinen tot maximaal 1920x1920, 10% gecomprimeerd. Kan geheugenproblemen veroorzaken op low-end apparaten en kan problemen veroorzaken bij het verzenden van e-mails met veel foto's.",
  "WIDGET_PROPERTY_FILENAME": "Bestandsnaam",
  "WIDGET_PROPERTY_FILENAME_DESCRIPTION": "Kies de bestandsnaam van de foto voor in de export.",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE": "Sta toe om een foto van het apparaat toe te voegen",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE_DESCRIPTION": "Sta het toe om een eerder gemaakte foto te selecteren.",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW": "Toon preview op volledige grootte",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW_DESCRIPTION": "Als je een preview van de foto op volledige grootte in het formulier wil tonen.",
  "WIDGET_PROPERTY_SAVE_A_COPY_ON_THE_DEVICE": "Bewaar een kopie van de foto op het apparaat",
  "WIDGET_PROPERTY_PENCIL_SIZE": "Lijndikte",
  "WIDGET_PROPERTY_PENCIL_SIZE_DESCRIPTION": "Vergroot/verklein de lijndikte.",
  "WIDGET_PROPERTY_WIDE": "Maak breder",
  "WIDGET_PROPERTY_WIDE_DESCRIPTION": "Maak de handtekening breder door de hoogte te verkleinen.",
  "WIDGET_PROPERTY_TEXT": "Tekst",
  "WIDGET_PROPERTY_HEADER_SIZE": "Grootte van de header",
  "WIDGET_PROPERTY_HEADER_SIZE_DESCRIPTION": "Variërend van h1 (grootste) tot h6 (kleinste).",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT": "Vul de huidige datum in",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT_DESCRIPTION": "Vul automatisch de datum/tijd van vandaag in bij het openen van het formulier.",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY": "Sorteer Alfabetisch",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY_DESCRIPTION": "Sorteer de opties alfabetisch.",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS": "Kies meerdere opties als antwoord",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS_DESCRIPTION": "Kies meerdere opties als antwoord. De antwoorden worden gescheiden door komma's opgeslagen in de registratie.",
  "WIDGET_PROPERTY_EXPAND": "Klap uit",
  "WIDGET_PROPERTY_EXPAND_DESCRIPTION": "Laat de antwoorden zien.",
  "WIDGET_PROPERTY_TARGET_VIEW": "Doelweergave",
  "WIDGET_PROPERTY_TARGET_VIEW_DESCRIPTION": "Kies het formulier wat je wil koppelen.",
  "WIDGET_PROPERTY_IMAGE": "Afbeelding",
  "WIDGET_PROPERTY_IMAGE_DESCRIPTION": "Deze afbeelding wordt gebruikt als thumbnail in de app.",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT": "Naam van de knop",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT_DESCRIPTION": "Naam van de knop om een nieuw subformulier in te vullen.",
  "WIDGET_PROPERTY_SUBFORM": "Subformulier",
  "WIDGET_PROPERTY_SUBFORM_DESCRIPTION": "Kies het formulier dat je wil gebruiken om een samengesteld object te maken.",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP": "Samenvatting in app",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP_DESCRIPTION": "Geef aan hoe een ingevuld subformulier weer wordt gegeven in de app. Gebruik hiervoor de beschikbare parameters en voeg eventueel tekst toe.",
  "WIDGET_PROPERTY_MINIMUM": "Minimum",
  "WIDGET_PROPERTY_MINIMUM_DESCRIPTION": "Het minimum aantal keer dat een subformulier moet worden ingevuld.",
  "WIDGET_PROPERTY_MAXIMUM": "Maximum",
  "WIDGET_PROPERTY_MAXIMUM_DESCRIPTION": "Het maximum aantal keer dat een subformulier moet worden ingevuld.",
  "WIDGET_PROPERTY_STEP_SIZE": "Stapgrootte",
  "WIDGET_PROPERTY_STEP_SIZE_DESCRIPTION": "Indien ingesteld op 3, worden de stappen een veelvoud van 3.",
  "WIDGET_PROPERTY_HIDE_VALUE": "Verberg nummering",
  "WIDGET_PROPERTY_HIDE_VALUE_DESCRIPTION": "Bepaalt of de nummering wel of niet wordt weergegeven in de app.",
  "WIDGET_PROPERTY_HTML_CODE": "HTML code",
  "WIDGET_PROPERTY_HTML_CODE_DESCRIPTION": "Voeg HTML toe dat inline CSS ondersteunt.",
  "WIDGET_PROPERTY_RESOURCE": "Bron",
  "WIDGET_PROPERTY_RESOURCE_DESCRIPTION": "Selecteer een afbeelding uit de galerij.",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH": "Maximale breedte",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH_DESCRIPTION": "De breedte van de afbeelding in de app.",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT": "Maximale Hoogte",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT_DESCRIPTION": "De hoogte van de afbeelding in de app.",
  "WIDGET_PROPERTY_DATA_SOURCE": "Databron",
  "WIDGET_PROPERTY_DATA_SOURCE_DESCRIPTION": "Selecteer een van jouw databronnen om in te zoeken. Nieuwe databronnen kun je toevoegen via de Databronnen pagina in het hoofdmenu.",
  "WIDGET_PROPERTY_FILTER": "Filter",
  "WIDGET_PROPERTY_FILTER_DESCRIPTION": "Filter de zoekresultaten met de waarde van andere widgets in het formulier.",
  "WIDGET_PROPERTY_LIST_COLORS": "Kleuren",
  "WIDGET_PROPERTY_LIST_COLORS_DESCRIPTION": "Voeg kleuren toe aan de databron items die overeenkomen met de zoekopdracht.",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING": "Barcodes scannen",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING_DESCRIPTION": "De gebruiker kan de databron doorzoeken met behulp van de barcode scanner.",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY": "Onthoud de laatste keuze",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY_DESCRIPTION": "De laatste zoekopdracht wordt automatisch uitgevoerd.",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE": "Sta toe om de gebruiker de afbeelding te laten wijzigen",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE_DESCRIPTION": "Gebruikers kunnen zelf een foto of afbeelding toevoegen.",
  "WIDGET_PROPERTY_PINS": "Pins",
  "WIDGET_PROPERTY_PINS_DESCRIPTION": "Definieer een of meer pinnen die op de afbeelding geplaatst kunnen worden. Iedere pin heeft haar eigen pictogram. Bij het plaatsen van de pin, opent het subformulier. <br><br>De pinnen krijgen automatisch een nummer in de app. Vul de Tracking-ID in om de pinnen zelf een waarde mee te geven.",
  "WIDGET_PROPERTY_REQUIRED": "Verplicht",
  "WIDGET_PROPERTY_REQUIRED_DESCRIPTION": "Deze widget moet worden ingevuld om het formulier te kunnen versturen.",
  "WIDGET_PROPERTY_REMEMBER_INPUT": "Onthoud invoer",
  "WIDGET_PROPERTY_REMEMBER_INPUT_DESCRIPTION": "Vink dit aan om de laatste invoer van de gebruiker op dat apparaat te onthouden voor de volgende registratie. Als je ook een standaardwaarde hebt ingesteld, let er dan op dat de invoer wordt overschreven.",
  "WIDGET_PROPERTY_DECIMAL_MARK": "Decimaalteken",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT": "Minimale aantal",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT_DESCRIPTION": "De app staat het kiezen van een waarde lager dan deze niet toe.",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT": "Maximale aantal",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT_DESCRIPTION": "De app staat het niet toe om een waarde te kiezen die hoger is.",
  "WIDGET_PROPERTY_CLICK_TO_VIEW": "Klik om deze afbeelding te zien",
  "WIDGET_PROPERTY_CLICK_TO_VIEW_DESCRIPTION": "Selecteer dit als je een preview van de foto op de volledige grootte wil tonen door op de foto te klikken.",
  "WIDGET_PROPERTY_DEFAULT_TO_CURRENT_LOCATION": "Verplaats naar huidige locatie",
  "INTEGRATION_TASK": "Taak",
  "INTEGRATION_TASK_DESCRIPTION": "Met deze integratie kun je een taak versturen op basis van een inkomende registratie.",
  "INTEGRATION_MAILCHIMP": "Mailchimp",
  "INTEGRATION_MAILCHIMP_DESCRIPTION": "This plugin allows you to send a mail using Mailchimp. You can use the data in your registration as placeholders in your Mailchimp template.",
  "INTEGRATION_DELETE": "Delete",
  "INTEGRATION_DELETE_DESCRIPTION": "Met deze integratie kun je registraties van een formulier automatisch verwijderen. Zorg ervoor dat je deze integratie als laatste stap aan het formulier toevoegt. Nadat een registratie verwijderd is, kan deze niet meer worden hersteld.",
  "INTEGRATION_WORD": "Word",
  "INTEGRATION_WORD_DESCRIPTION": "Met deze integratie kun je Microsoft Word gebruiken voor het opmaken van rapporten. Na het invullen van een formulier ontvang je een e-mail met een Word- of PDF-rapport.",
  "INTEGRATION_WEBDAV": "WebDAV",
  "INTEGRATION_WEBDAV_DESCRIPTION": "Met de WebDAV integratie verstuur je PDF-bestanden, vanuit e-mails, naar een WebDAV-server.",
  "INTEGRATION_GOOGLE_SHEETS": "Google Sheets",
  "INTEGRATION_GOOGLE_SHEETS_DESCRIPTION": "Gebruik deze integratie om je ingevulde formulieren naar een Google Spreadsheet te sturen. Je kunt de Google Spreadsheet-ID ophalen uit de URL. Opmerking: de ingevulde formulieren worden eenvoudig als een nieuwe rij in de sheet toegevoegd. We werken de headers niet bij als je het formulier bijwerkt. Dit kun je zelf doen. Deze integratie ondersteunt geen afbeeldingen/bestanden. Alle data uit Subform-, Pin- en Search-widgets worden  toegevoegd aan een enkele cel. Bij formulieren met regels en lege velden kunnen gegevens in de verkeerde kolom worden geplaatst.",
  "INTEGRATION_MONGODB_INSERT": "MongoDB Insert",
  "INTEGRATION_MONGODB_INSERT_DESCRIPTION": "Met de MongoDB Insert integratie verstuur je de registratiedata naar een Mongo-database.",
  "INTEGRATION_ONEDRIVE": "OneDrive",
  "INTEGRATION_ONEDRIVE_DESCRIPTION": "Met deze integratie kun je de PDF die aan het formulier is toegevoegd, verzenden naar Microsoft OneDrive.",
  "INTEGRATION_EXCEL": "Excel",
  "INTEGRATION_EXCEL_DESCRIPTION": "Met deze integratie kun je data exporteren naar Excel. Upload je Excel.xlsx template en ontvang jouw data in een Excel-bestand.",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE": "Google Sheets Cell Update",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE_DESCRIPTION": "Werk een specifieke cel in een bestaande sheet bij. Met deze integratie kunt je een sheet bijwerken die ook wordt gebruikt als geïmporteerde databron in MoreApp.",
  "INTEGRATION_MESSAGE_BOARD": "Message Board",
  "INTEGRATION_MESSAGE_BOARD_DESCRIPTION": "Allow messages to be sent",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT": "Google Sheet accepteren",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT_DESCRIPTION": "Not for production",
  "INTEGRATION_PROPERTY_FOLDER": "Map",
  "INTEGRATION_PROPERTY_FOLDER_DESCRIPTION": "De map waarvoor een taak verstuurd zou moeten worden.",
  "INTEGRATION_PROPERTY_FORM": "Formulier",
  "INTEGRATION_PROPERTY_FORM_DESCRIPTION": "Het formulier (in de geselecteerde map) dat als doel voor de taak moet worden gebruikt.",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS": "Ontvangers (door komma's gescheiden lijst met e-mailadressen)",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS_DESCRIPTION": "Deze ontvangers hebben toegang nodig tot volgende formulier in de workflow.",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS": "Dynamische ontvangers",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Gebruik de e-mailadressen, ingevuld in de Email-widget als ontvanger.",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK": "Bericht voor de taak",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK_DESCRIPTION": "Bericht voor de taak",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS": "Vertraging (in dagen)",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS_DESCRIPTION": "Het aantal dagen dat de registratie moet blijven bestaan voordat deze wordt verwijderd.",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT": "Output formaat",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT_DESCRIPTION": "Kies het output formaat wat als bijlage van de e-mail wordt gebruikt.",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL": "Stuur antwoord naar (optioneel)",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL_DESCRIPTION": "Het e-mailadres waar de ontvangers op kunnen antwoorden.",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL": "Van (optioneel)",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL_DESCRIPTION": "De (bedrijfs)naam waarvan de e-mails afkomstig zijn.",
  "INTEGRATION_PROPERTY_RECIPIENTS": "Ontvangers",
  "INTEGRATION_PROPERTY_RECIPIENTS_DESCRIPTION": "Door komma's gescheiden lijst van ontvangers.",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM": "Verzenden naar de gebruiker die het formulier heeft ingevuld",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM_DESCRIPTION": "Voeg de gebruiker die het formulier heeft ingevuld toe als ontvanger.",
  "INTEGRATION_PROPERTY_CC": "CC",
  "INTEGRATION_PROPERTY_CC_DESCRIPTION": "Door komma's gescheiden lijst van CC ontvangers.",
  "INTEGRATION_PROPERTY_BCC": "BCC",
  "INTEGRATION_PROPERTY_BCC_DESCRIPTION": "Door komma's gescheiden lijst van BCC ontvangers.",
  "INTEGRATION_PROPERTY_SUBJECT": "Onderwerpregel",
  "INTEGRATION_PROPERTY_SUBJECT_DESCRIPTION": "Onderwerpregel waarin je placeholders kunt gebruiken.",
  "INTEGRATION_PROPERTY_BODY": "Inhoud van de e-mail",
  "INTEGRATION_PROPERTY_BODY_DESCRIPTION": "Inhoud van de e-mail waarin je placeholders kunt gebruiken.",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY": "Foto kwaliteit",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Als de registratie veel foto's met een hoge resolutie bevat, is het aanbevolen de kwaliteit te verlagen. De e-mail wordt anders mogelijk te groot voor de ontvangende mailbox.",
  "INTEGRATION_PROPERTY_FILENAME": "Bestandsnaam",
  "INTEGRATION_PROPERTY_FILENAME_DESCRIPTION": "Bestandsnaam waarin je placeholders kunt gebruiken.",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE": "Upload een Word template",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE_DESCRIPTION": "De te gebruiken Word template.",
  "INTEGRATION_PROPERTY_SERVER": "Server",
  "INTEGRATION_PROPERTY_SERVER_DESCRIPTION": "URL naar de server, wat wordt gebruikt als basispad. Bijvoorbeeld: https://mijn.server.com",
  "INTEGRATION_PROPERTY_PATH": "Pad",
  "INTEGRATION_PROPERTY_PATH_DESCRIPTION": "Pad waar de PDF's moeten worden opgeslagen, waarin je placeholders kunt gebruiken. Bijvoorbeeld: /drive/${number}/",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME": "WebDAV gebruikersnaam",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME_DESCRIPTION": "Gebruikersnaam voor verificatie met de WebDAV server.",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD": "WebDAV wachtwoord",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD_DESCRIPTION": "Wachtwoord voor authenticatie met de WebDAV server.",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN": "Google login",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN_DESCRIPTION": "Kies een Google account zodat wij data naar Google Spreadsheets kunnen versturen.",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID": "Spreadsheet ID",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID_DESCRIPTION": "De unieke ID van de spreadsheet, weergegeven in de URL, zoals https://docs.google.com/spreadsheets/d/<b>mijn-spreadsheet-id</b>.",
  "INTEGRATION_PROPERTY_SHEET_NAME": "Sheet naam",
  "INTEGRATION_PROPERTY_SHEET_NAME_DESCRIPTION": "De naam van de sheet, die meestal standaard is ingesteld op Blad1.",
  "INTEGRATION_PROPERTY_METADATA": "Metadata",
  "INTEGRATION_PROPERTY_METADATA_DESCRIPTION": "Kies welke metadata je in de export op wil nemen. Deze data is zichtbaar in de eerste kolommen van de sheet.",
  "INTEGRATION_PROPERTY_MONGODB_HOST": "MongoDB host",
  "INTEGRATION_PROPERTY_MONGODB_HOST_DESCRIPTION": "Het IP-adres van de MongoDB host.",
  "INTEGRATION_PROPERTY_MONGODB_PORT": "MongoDB poort",
  "INTEGRATION_PROPERTY_MONGODB_PORT_DESCRIPTION": "De poort waar MongoDB naar luistert.",
  "INTEGRATION_PROPERTY_USE_SSL": "Gebruik SSL",
  "INTEGRATION_PROPERTY_USE_SSL_DESCRIPTION": "Gebruik een beveiligde TLS verbinding.",
  "INTEGRATION_PROPERTY_USERNAME": "Gebruikersnaam",
  "INTEGRATION_PROPERTY_USERNAME_DESCRIPTION": "De gebruikersnaam van de Mongo gebruiker die we kunnen verifiëren.",
  "INTEGRATION_PROPERTY_PASSWORD": "Wachtwoord",
  "INTEGRATION_PROPERTY_PASSWORD_DESCRIPTION": "Het wachtwoord van de Mongo gebruiker die we kunnen verifiëren.",
  "INTEGRATION_PROPERTY_DATABASE": "Database",
  "INTEGRATION_PROPERTY_DATABASE_DESCRIPTION": "De database waarin je de registraties wil opslaan.",
  "INTEGRATION_PROPERTY_COLLECTION": "Collectie",
  "INTEGRATION_PROPERTY_COLLECTION_DESCRIPTION": "De collectie waarin je de registraties wil opslaan.",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET": "Search-widget",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET_DESCRIPTION": "Kies de Search-widget.",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN": "OneDrive login",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN_DESCRIPTION": "Kies een OneDrive account zodat we daar bestanden in kunnen opslaan.",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE": "Voeg CSV bestand toe",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE_DESCRIPTION": "Voegt automatisch het CSV bestand (door komma's gescheiden waarden) van de registraties toe aan de OneDrive map.",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE": "Upload een Excel template",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE_DESCRIPTION": "De te gebruiken Excel template.",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME": "Bestandsnaam Excel bestand",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME_DESCRIPTION": "Naam van het Excel bestand zoals het wordt toegevoegd aan de e-mail.",
  "INTEGRATION_PROPERTY_COPY_TO_USER": "Stuur een e-mail naar de gebruiker",
  "INTEGRATION_PROPERTY_COPY_TO_USER_DESCRIPTION": "Stuur een e-mail naar de gebruiker die het formulier heeft ingevuld.",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES": "Voeg afbeeldingen toe",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES_DESCRIPTION": "Voeg foto's en handtekeningen als bijlage toe aan de e-mail.",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET": "Nieuwe waarde van de widget",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET_DESCRIPTION": "Kies de widget die een nieuwe waarde krijgt.",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE": "De bij te werken kolomnaam",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE_DESCRIPTION": "Definieer de kolom die je wil bijwerken met de waarde uit de geselecteerde widget.",
  "INTEGRATION_FTPS": "FTPS",
  "INTEGRATION_FTPS_DESCRIPTION": "De FTPS-integratie verstuurt de registratiedata naar de opgegeven FTPS-server.",
  "INTEGRATION_PROPERTY_PROTOCOL": "Protocol",
  "INTEGRATION_PROPERTY_PROTOCOL_DESCRIPTION": "Het protocol dat moet worden gebruikt bij het verbinden met de FTP-server",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION": "Schakel SSL verificatie uit",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION_DESCRIPTION": "Je kunt gebruik maken van deze optie wanneer je een self-signed certificaat of een ongeldig certificaat chain hebt (alleen van toepassing wanneer je gebruik maakt van FTPS)",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE": "Gebruik 'impliciete' FTPS-modus",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE_DESCRIPTION": "Schakel tussen 'impliciete' en 'expliciete FTPS-modus' (alleen van toepassing bij gebruik van FTPS).",
  "INTEGRATION_PROPERTY_FTP_SERVER_DESCRIPTION": "URL naar de server zonder het protocol, wordt gebruikt als basispad. Bijv.: mijn.server.com.",
  "INTEGRATION_PROPERTY_PORT": "Poort",
  "INTEGRATION_PROPERTY_PORT_DESCRIPTION": "Poort naar de FTP(S)-server, deze staat standaard op 21 of 990 indien niet ingevuld.",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER": "Plaats iedere registratie in een eigen map",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER_DESCRIPTION": "Voeg automatisch '/customerId/form-name/serial-number' toe aan het externe pad om een ​​uniek pad per registratie te maken.",
  "INTEGRATION_PROPERTY_FTP_USERNAME": "FTP gebruikersnaam",
  "INTEGRATION_PROPERTY_FTP_USERNAME_DESCRIPTION": "Gebruikersnaam die je gebruikt bij de verificatie van de WebDAV-server.",
  "INTEGRATION_PROPERTY_FTP_PASSWORD": "FTP wachtwoord",
  "INTEGRATION_PROPERTY_FTP_PASSWORD_DESCRIPTION": "Wachtwoord dat je gebruikt bij de verificatie van de WebDAV-server.",
  "INTEGRATION_PROPERTY_INCLUDE_PDF": "Voeg PDF toe",
  "INTEGRATION_PROPERTY_INCLUDE_PDF_DESCRIPTION": "Geef aan of je de PDF ook wil toevoegen.",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT": "Sla PDF op bij e-mails zonder ontvanger",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT_DESCRIPTION": "Bepaal of PDF's worden opgeslagen bij e-mails zonder ontvanger.",
  "INTEGRATION_PROPERTY_INCLUDE_FILES": "Voeg bestanden toe",
  "INTEGRATION_PROPERTY_INCLUDE_FILES_DESCRIPTION": "Geef aan of je bestanden ook wil toevoegen.",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE": "Upload een Excel template",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE_DESCRIPTION": "De te gebruiken Excel template",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS": "Gebruik bestaande scheidingstekens",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS_DESCRIPTION": "Gebruik deze optie als je '${' wilt gebruiken als beginkarakter voor placeholders (dit wordt alleen aanbevolen voor oudere bestaande sjablonen).",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME": "Bestandsnaam Excel bestand",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME_DESCRIPTION": "Naam van het Excel bestand zoals het wordt toegevoegd aan de e-mail.",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS": "Ontvangers",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS_DESCRIPTION": "Een lijst van ontvangers, gescheiden door een komma.",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS": "Dynamische ontvangers",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Gebruik de e-mailadressen, ingevuld in de Email-widget als ontvanger.",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER": "Kopieer naar de gebruiker",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER_DESCRIPTION": "Stuur een kopie naar de gebruiker die het formulier heeft ingevuld",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY": "Fotokwaliteit",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Met een hogere kwaliteit duurt het langer om de e-mail te verwerken",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT": "Onderwerp",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT_DESCRIPTION": "Onderwerpregel waarin je optionele placeholders kunt gebruiken",
  "INTEGRATION_EXCEL_PROPERTY_BODY": "Inhoud",
  "INTEGRATION_EXCEL_PROPERTY_BODY_DESCRIPTION": "Inhoud van de e-mail waarin je optionele placeholders kunt gebruiken.",
  "DISABLED_DURING_TRIAL": "Je kunt tijdens de proefperiode geen gebruik maken van deze feature.",
  "LOGIN_FAILED_TIME_RELATED_TITLE": "Controleer je tijdsinstelling",
  "LOGIN_FAILED_TIME_RELATED_SUBTITLE": "Je kunt niet inloggen omdat de tijd niet klopt.",
  "LOGIN_FAILED_TIME_RELATED_DESCRIPTION": "<b>FIX</b>: verander de tijd en tijdzone van je apparaat zodat dit aansluit bij de tijd en tijdzone van je huidige locatie. Probeer het opnieuw.",
  "LOGIN_FAILED_TIME_RELATED_LINK": "Log opnieuw in",
  "TOUR_PROGRESS_STEP1_LABEL": "Hoe het werkt",
  "TOUR_PROGRESS_STEP2_LABEL": "Vertel ons meer",
  "TOUR_PROGRESS_STEP3_LABEL": "Je eerste formulier",
  "TOUR_SKIP": "Tour overslaan",
  "TOUR_STEP1_TRIAL": "Je start nu met de gratis proefperiode!",
  "TOUR_STEP1_BUILD_FORMS_WITH_COMPUTER": "Beheer formulieren op de desktop",
  "TOUR_STEP1_FILL_FORMS_WITH_DEVICES": "Vul formulieren in met jouw telefoon of tablet",
  "TOUR_STEP1_RECEIVE_DATA": "Ontvang het rapport in jouw inbox",
  "TOUR_STEP2_HEADER": "Vertel ons meer",
  "TOUR_STEP3_HEADER": "Hoe kunnen we je bereiken?",
  "TOUR_ERROR_MODAL_TITLE": "Oeps...",
  "TOUR_ERROR_MODAL_MESSAGE": "Oeps.. helaas ging er iets fout tijdens de tour. Probeer {{appName}} zelf uit in de tussentijd!",
  "TOUR_ERROR_MODAL_BUTTON": "Verken {{appName}}",
  "FIRST_NAME": "Voornaam",
  "LAST_NAME": "Achternaam",
  "PAGE_CONTROL_PAGE_SIZE": "Aantal pagina's",
  "PAGE_CONTROL_TOTAL_ITEMS": "Totaal",
  "VIEW_MANAGEMENT_OVERVIEW": "Overzicht",
  "VIEW_MANAGEMENT_REGISTRATIONS": "Registraties",
  "VIEW_MANAGEMENT_TASKS": "Taken",
  "VIEW_MANAGEMENT_OVERVIEW_COSTS": "Kosten",
  "VIEW_MANAGEMENT_OVERVIEW_STATUS": "Status",
  "VIEW_MANAGEMENT_OVERVIEW_PUBLISHED_ON": "Op",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM": "Bewerk",
  "VIEW_MANAGEMENT_OVERVIEW_SHARING": "Delen",
  "FORM_REMOVE_MODAL_TITLE": "Verwijder formulier",
  "FORM_REMOVE_MODAL_MESSAGE": "Weet je zeker dat je dit formulier wilt verwijderen?",
  "REMOVE_DRAFT_TITLE": "Verwijder concept",
  "REMOVE_DRAFT_MESSAGE": "Weet je zeker dat je dit concept wilt verwijderen?",
  "TEMPLATE_REMOVE_MODAL_TITLE": "Verwijder Sjabloon",
  "TEMPLATE_REMOVE_MODAL_MESSAGE": "Weet je zeker dat je deze template wilt verwijderen?",
  "UNPUBLISHED": "Niet gepubliceerd",
  "PUBLISHED": "Gepubliceerd",
  "PUBLISHED_UPDATED": "Gepubliceerd, update beschikbaar",
  "VIEW_MANAGEMENT_OVERVIEW_USER_MANAGEMENT": "Gebruikersbeheer",
  "VIEW_MANAGEMENT_OVERVIEW_PLACE_ON_MARKETPLACE": "Plaats formulier in Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE": "Gedeeld op Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE_HIDDEN": "Verborgen van Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_VIEW_IN_MARKETPLACE": "Bekijk in Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_TEMPLATE_BUTTON": "Bewerk",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_BUTTON": "Update naar laatste versie",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_ERROR_MESSAGE": "Je formulier kon niet geüpdate worden naar de laatste versie. Probeer het later opnieuw.",
  "VIEW_MANAGEMENT_OVERVIEW_HIDE_FORM_TEMPLATE_BUTTON": "Verberg van Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_SHOW_FORM_TEMPLATE_BUTTON": "Toon in Marketplace",
  "PLACE_ON_MARKETPLACE_PANEL_HEADER": "Plaats formulier in Marketplace",
  "PLACE_ON_MARKETPLACE_PANEL_QUESTION": "Wil je het formulier delen met alle andere users?",
  "PLACE_ON_MARKETPLACE_PANEL_EXPLANATION": "Vul eerst dit formulier in. Deze informatie (met uitzondering van je bankrekening) is zichtbaar op de Marktplaats.",
  "PLACE_ON_MARKETPLACE_EDIT_DEVELOPER_BUTTON": "Update informatie",
  "FORM_TEMPLATE_NAMESPACE_LABEL": "Namespace",
  "FORM_TEMPLATE_NAMESPACE_PLACEHOLDER": "Example: com.mycompany",
  "FORM_TEMPLATE_KEY_LABEL": "Key",
  "FORM_TEMPLATE_KEY_PLACEHOLDER": "Example: specialform",
  "FORM_TEMPLATE_TYPE_LABEL": "Type",
  "FORM_TEMPLATE_NAME_LABEL": "Naam",
  "FORM_TEMPLATE_NAME_PLACEHOLDER": "Geef je Sjabloon een naam",
  "FORM_TEMPLATE_DESCRIPTION_LABEL": "Beschrijving",
  "FORM_TEMPLATE_DESCRIPTION_PLACEHOLDER": "Beschrijf je Sjabloon",
  "FORM_TEMPLATE_LOGO_LABEL": "Logo",
  "FORM_TEMPLATE_LOGO_PLACEHOLDER": "De URL van het logo voor je Sjabloon",
  "DIRECT_FORM_LINK": "Genereer directe URL",
  "DIRECT_FORM_LINK_GENERATED": "Open directe URL",
  "DIRECT_FORM_LINK_REVOKE_MODAL_TITLE": "Trek directe URL in",
  "DIRECT_FORM_LINK_REVOKE_MODAL_MESSAGE": "Weet je zeker dat je de URL wilt intrekken voor directe toegang tot je formulier? Dit betekent dat de URL niet meer zal werken.",
  "PUBLISH": "Publiceer",
  "PUBLISH_FORM_VERSION": "Publiceer deze versie",
  "PUBLISH_FORM_VERSION_MESSAGE": "Weet je zeker dat je deze versie van het formulier wilt publiceren?",
  "PUBLISHED_BY": "Gepubliceerd door",
  "PUBLISHED_ON": "Gepubliceerd",
  "REMARKS": "Opmerkingen",
  "DRAFT": "Concept",
  "FORM_HISTORY": "Versiegeschiedenis",
  "FORM_DESCRIPTION_PLACEHOLDER": "Voeg hier een korte beschrijving van je formulier toe",
  "FORM_MOVE_MODAL_TITLE": "Verplaats",
  "FORM_SAVE_AS_TEMPLATE": "Opslaan als sjabloon",
  "FORM_SAVE_AS_TEMPLATE_MESSAGE": "Weet je zeker dat je dit formulier wil opslaan als sjabloon?",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_LABEL": "Verplaats het formulier naar de geselecteerde map",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_PLACEHOLDER": "Selecteer map",
  "FORM_VERSION_DRAFT_ALREADY_AVAILABLE": "Het lijkt erop dat jij of een collega dit formulier ergens anders aan het bewerken zijn. Probeer opnieuw als je zeker weet dat je de enige bent die dit formulier bewerkt.",
  "INTEGRATIONS_EOL_MESSAGE": "Dit formulier maakt gebruik van een integratie die wij binnenkort niet meer ondersteunen. Verwijder of vervang deze door een andere integratie of webhook. Voor vragen of advies <a href=\"https://help.moreapp.com/en/support/tickets/new\">neem contact op met Support</a>.",
  "DATASOURCE_PICKER_ADD": "Nieuwe databron",
  "SEARCH_DATASOURCE_PLACEHOLDER": "Zoek naar databronnen",
  "GROUP_PICKER_ADD_USER": "Gebruiker(s) uitnodigen",
  "GROUP_PICKER_ADD_GROUP": "+ Nieuwe groep",
  "GROUP_PICKER_ALL_USERS": "Alle gebruikers",
  "GROUP_PICKER_INVITED": "(Uitgenodigd)",
  "MANAGE_ROLES": "Beheer rollen",
  "ROLE": "Rol",
  "SEARCH_GROUP_PLACEHOLDER": "Zoek naar groepen",
  "BILLING_TITLE": "Betalingen",
  "BILLING_OVERVIEW_MENU_ITEM": "Overzicht",
  "BILLING_INVOICES_MENU_ITEM": "Facturen",
  "BILLING_USAGE_MENU_ITEM": "Gebruik",
  "SUBTOTAL": "Subtotaal",
  "TOTAL": "Totaal",
  "NEXT_INVOICE": "Volgende factuur",
  "PREVIEW_CHANGE_EXPLANATION": "Hierbij de opbouw van de eerstvolgende factuur na het toepassen van de wijziging. Bekijk deze <a href=\"https://help.moreapp.com/support/solutions/folders/13000014069\" target=\"_blank\">Help Center</a> post voor meer uitleg over de opbouw van de factuur.",
  "PREVIEW_CHANGE": "Preview wijziging",
  "CHANGE_SUBSCRIPTION_BUTTON": "Abonnement wijzigen",
  "SCHEDULED_SUBSCRIPTION_CHANGES_BUTTON": "Bekijk wijziging",
  "SCHEDULED_SUBSCRIPTION_CHANGES": "Geplande wijziging abonnement",
  "SCHEDULED_SUBSCRIPTION_CHANGES_ON": "Gepland",
  "SCHEDULED_CANCEL_MESSAGE": "Jouw abonnement is opgezegd na afloop van de huidige factureringsperiode ",
  "CANCEL_SUBSCRIPTION_SCHEDULE_BUTTON": "Annuleer wijziging",
  "SELECT_PLAN": "Kies jouw abonnement",
  "YOUR_SELECTED_PLAN": "Jouw geselecteerde abonnement",
  "LOADING_PLANS": "Beschikbare abonnementen laden",
  "MONTHLY_PLAN": "Maandabonnement",
  "YEARLY_PLAN": "Jaarabonnement",
  "MONTH_SHORT": "maand",
  "YEAR_SHORT": "jaar",
  "TRIAL_ACTIVE": "Trial actief",
  "DAYS": "dagen",
  "TRIAL_DAYS_REMAINING": "resterende dagen",
  "TRIAL_ENDS_TODAY": "Jouw proefperiode loopt vandaag af",
  "TRIAL_ENDS_TOMORROW": "Jouw proefperiode loopt morgen af",
  "TRIAL_ENDS_ON": "Jouw proefperiode loopt af op",
  "SUBMISSIONS": "Registraties",
  "USERS": "Gebruikers",
  "USAGE": "Gebruik",
  "UNLIMITED": "Onbeperkt",
  "CHANGE_PLAN": "Wijzig Abonnement",
  "CANCEL_PLAN": "Annuleer Abonnement",
  "UNCANCEL_PLAN": "Opzegging annuleren",
  "PLAN_MORE_OPTIONS": "Meer opties",
  "DELETE_ACCOUNT": "Verwijder Account",
  "TERMINATION_OPEN_INVOICE_MODAL_TITLE": "Je hebt nog een openstaande factuur",
  "TERMINATION_OPEN_INVOICE_MODAL_MESSAGE": "Je hebt nog een openstaande factuur en kunt daarom deze actie niet uitvoeren. Navigeer naar jouw facturen om de betaling te voldoen.",
  "TERMINATION_OPEN_INVOICE_MODAL_GOTO_INVOICES_ACTION": "Ga naar Facturen",
  "CANCEL_PLAN_MODAL_TITLE": "Annuleer Abonnement",
  "CANCEL_PLAN_MODAL_MESSAGE": "Je kunt ons product blijven gebruiken tot het einde van de betalingscyclus. Daarna worden de registraties niet meer verstuurd. Je hebt nog 30 dagen toegang tot je Account om je gegevens in te zien en te exporteren. Daarna zullen we je Account verwijderen",
  "UNCANCEL_PLAN_MODAL_TITLE": "Opzegging annuleren",
  "UNCANCEL_PLAN_MODAL_MESSAGE": "Geweldig, dus je bent van gedachten veranderd en je wilt ons product blijven gebruiken?",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_TITLE": "Abonnement is al ingepland om te annuleren",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_MESSAGE": "Het abonnement staat ingepland om geannuleerd te worden aan het einde van de betalingscyclus.",
  "DOWNGRADE_FEEDBACK_TITLE": "Feedback",
  "DOWNGRADE_DESCRIPTION": "Laat ons weten hoe wij ons product kunnen verbeteren. Jouw feedback is belangrijk voor ons.</p>",
  "TERMINATION_FEEDBACK_MODAL_TITLE": "Feedback",
  "TERMINATION_FEEDBACK_MODAL_DESCRIPTION": "<h4>Het kan niet altijd een goede match zijn</h4><p>Laat ons weten hoe wij ons product kunnen verbeteren. Jouw feedback is belangrijk voor ons.</p>",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_CANCEL_PLAN": "Waarom wil jij je abonnement annuleren?",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_DELETE_ACCOUNT": "Waarom wil jij je account verwijderen?",
  "TERMINATION_FEEDBACK_MODAL_REASON_FOUND_ALTERNATIVE_LABEL": "Een alternatieve tool gevonden",
  "TERMINATION_FEEDBACK_MODAL_REASON_TOO_EXPENSIVE_LABEL": "Het is te duur",
  "TERMINATION_FEEDBACK_MODAL_REASON_NO_LONGER_NEEDED_LABEL": "Niet langer nodig",
  "TERMINATION_FEEDBACK_MODAL_REASON_BANKRUPT_LABEL": "Mijn bedrijf is opgeheven",
  "TERMINATION_FEEDBACK_MODAL_REASON_UNHAPPY_LABEL": "Ik ben niet langer tevreden met het product",
  "TERMINATION_FEEDBACK_MODAL_REASON_MISSING_FEATURE_LABEL": "Het mist een feature",
  "TERMINATION_FEEDBACK_MODAL_REASON_OTHER_LABEL": "Anders",
  "TERMINATION_FEEDBACK_MODAL_REASON_ERROR": "Selecteer minimaal één van de opties.",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_LABEL": "Is er nog iets dat je met ons wilt delen?",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_ERROR": "Voeg een opmerking toe.",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_CANCEL_PLAN": "Annuleer Abonnement",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_DELETE_ACCOUNT": "Verwijder Account",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_TITLE": "Ja, ik wil mijn Account verwijderen",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_MESSAGE": "Ik begrijp dat na <b>30 dagen</b>, mijn bedrijfsaccount <b>{{customerName}}</b> ({{customerId}}) en alle gerelateerde data definitief worden verwijderd.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_LABEL": "Bevestig dit door de naam van jouw Account in the vullen",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_ERROR": "De ingevulde naam van het Account komt niet overeen met de naam van het Account dat je wil verwijderen.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ACTION": "Verwijder Account",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ERROR": "Het is niet gelukt om het Account te verwijderen. Probeer het later nog eens.",
  "BILLING_PROFILE": "Betaalprofiel",
  "BILLING_PHONE_NUMBER": "Telefoonnummer",
  "INVOICE_ADDRESS": "Factuuradres",
  "PAYMENT_METHOD": "Betaalmethode",
  "UPCOMING_INVOICE": "Aankomende Factuur",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_TITLE": "Lopende proefperiode",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_CONTENT": "Het testen van ons Product is gratis tijdens de proefperiode. Door met MoreApp na je proefperiode? Voeg een betaalmethode toe om jouw eerste factuur te ontvangen.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_CONTENT": "Het testen van ons Product is gratis tijdens de proefperiode. Na afloop van de proefperiode, ontvang je deze volgende factuur.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_AMOUNT_DUE": "Openstaand bedrag na proefperiode",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_TITLE": "Geen aankomende facturen",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_CONTENT": "Jouw abonnement is geannuleerd. Er staan geen nieuwe facturen klaar. Jouw betalingscyclus eindigt op ",
  "CARD_HOLDER_NAME": "Naam Pashouder",
  "CARD_HOLDER_NAME_PLACEHOLDER": "bijv. Jan Smit",
  "ADD_CARD": "+ Betaalmethode Toevoegen",
  "MAKE_DEFAULT": "Maak standaard",
  "EXPIRES": "verloopt",
  "PAYMENT_METHOD_REMOVE_TITLE": "Betaalmethode verwijderen",
  "PAYMENT_METHOD_REMOVE_MESSAGE": "Weet je zeker dat je deze betaalmethode wil verwijderen?",
  "PLAN_LEAF": "Leaf",
  "PLAN_BRANCH": "Branch",
  "PLAN_TREE": "Tree",
  "PLAN_FOREST": "Forest",
  "PLAN_LEAF_DESCRIPTION": "Professionele formulieren",
  "PLAN_BRANCH_DESCRIPTION": "Geavanceerde dataverwerking",
  "PLAN_TREE_DESCRIPTION": "Voor het hele bedrijf",
  "PLAN_FOREST_DESCRIPTION": "Voor enterprises",
  "PLAN_LEAF_FEATURES_HEADER": "Features",
  "PLAN_BRANCH_FEATURES_HEADER": "Alles in Leaf +",
  "PLAN_TREE_FEATURES_HEADER": "Alles in Branch +",
  "PLAN_FOREST_FEATURES_HEADER": "Alles in Tree +",
  "PLAN_AMOUNT_OF_USERS_ONE": "Eén gebruiker",
  "PLAN_AMOUNT_OF_USERS_UNLIMITED": "Onbeperkt aantal gebruikers",
  "PLAN_AMOUNT_OF_SUBMISSIONS": "{{amount}} gratis registraties",
  "PLAN_AMOUNT_OF_TREES": "{{amount}} bomen",
  "PLAN_LEAF_PRICE_PER_EXTRA_SUBMISSION": "€0,40 / extra registratie",
  "PLAN_BRANCH_PRICE_PER_EXTRA_SUBMISSION": "€0,40 / extra registratie",
  "PLAN_TREE_PRICE_PER_EXTRA_SUBMISSION": "€0,40 / extra registratie",
  "PLAN_FOREST_PRICE_PER_EXTRA_SUBMISSION": "€0,10 / extra registratie",
  "FEATURE_FORM_BUILDER": "Form Builder",
  "FEATURE_APP": "App",
  "FEATURE_EXCEL_EXPORT": "Excel export",
  "FEATURE_EMAIL": "E-mail met PDF",
  "FEATURE_RULES": "Regels",
  "FEATURE_API": "API toegang",
  "FEATURE_MARKETPLACE_TEMPLATES": "Sjablonen",
  "FEATURE_TASKS": "Taken",
  "FEATURE_LOGO_PDF": "Jouw logo op de PDF",
  "FEATURE_DATA_IMPORT": "Data import",
  "FEATURE_MULTIPLE_PDF": "Meerdere e-mails",
  "FEATURE_ADVANCED_PDF": "Custom PDF",
  "FEATURE_DATA_IMPORT_AUTOMATIC": "Automatische data import",
  "FEATURE_SINGLE_SIGN_ON": "Single sign-on",
  "FEATURE_BRANDING": "Branding",
  "FEATURE_SSO": "Single sign-on",
  "FEATURE_SENDING_DOMAIN": "Custom Email Domain",
  "FEATURE_DEDICATED_SUPPORT": "Dedicated support",
  "VAT": "Btw",
  "APPLIED_BALANCE": "Toegepast saldo",
  "AMOUNT_DUE": "Te betalen",
  "REMAINING_BALANCE": "Resterend saldo",
  "CREDIT_CARD": "Creditcard",
  "SEPA_DIRECT_DEBIT": "Automatische incasso",
  "PAY_ON_FILE": "Handmatig",
  "NOT_ALLOWED_TO_PAY_ON_FILE": "Deze optie is niet beschikbaar in combinatie met je huidige abonnement",
  "SEPA_MANDATE": "Door het verstrekken van je IBAN geef je toestemming aan (A) MoreApp en Stripe, onze betalingsprovider, een opdracht te sturen aan je bank om je rekening te debiteren en aan (B) uw bank uw rekening te debiteren conform deze opdracht. Deze machtiging is uitsluitend bedoeld voor zakelijke incassotransacties tussen bedrijven. Na afschrijving heb je geen recht om het bedrag terug te laten boeken, maar je hebt tot en met de vervaldatum van de betaling wel het recht je bank te vragen uw rekening niet te debiteren. Vraag je bank naar de voorwaarden in dat geval.",
  "INVOICE_NUMBER": "Factuur nummer",
  "INVOICE_DATE": "Datum",
  "INVOICE_TOTAL": "Totaal",
  "INVOICE_PDF": "PDF",
  "INVOICE_STATUS": "Status",
  "BASE_MONTH": "Maandabonnement",
  "BASE_YEAR": "Abonnement jaarlijks",
  "FORMS_MONTH": "Gebruik maandelijks",
  "FORMS_YEAR": "Gebruik monthly",
  "BRANDING_MONTH": "Branding maandelijks",
  "BRANDING_YEAR": "Branding jaarlijks",
  "ADDON_MONTH": "Add-on maandelijks",
  "ADDON_YEAR": "Add-on jaarlijks",
  "MAINTENANCE_FTPS_MONTH": "Onderhoud - FTPS maandelijks",
  "MAINTENANCE_FTPS_YEAR": "Onderhoud - FPTS jaarlijks",
  "MANUAL_INVOICE_DESCRIPTION": "Facturen worden naar het factuur e-mailadres gestuurd",
  "USAGE_DAILY": "Dagelijks",
  "USAGE_MONTHLY": "Maandelijks",
  "MONTH": "Maand",
  "YEAR": "Jaar",
  "INVOICE_REFERENCE": "Aanvullende factuur informatie",
  "INVOICE_REFERENCE_DESCRIPTION": "De informatie wordt toegevoegd aan de factuur voor je administratie, zoals bijvoorbeeld een inkoop ordernummer.",
  "SUBSCRIPTION_CHANGE_IMMEDIATE": "Deze wijziging gaat per direct in",
  "SUBSCRIPTION_CHANGE_SCHEDULED": "Deze wijziging gaat in vanaf de volgende factuur",
  "TREES_PLANTED": "Bomen geplant",
  "TREES_PLANTED_TOTAL": "bomen geplant in totaal",
  "TREES_PLANTED_PER_MONTH": "bomen geplant per maand",
  "TREES_PLANTED_PER_YEAR": "bomen geplant per jaar",
  "DOWNGRADE_QUESTION": "Waarom wil je je abonnement wijzigen?",
  "DOWNGRADE_NOT_NEEDED": "Niet meer nodig",
  "DOWNGRADE_NOT_NEEDED_DESCRIPTION": "Opmerkingen",
  "DOWNGRADE_ALTERNATIVE": "Een alternatief gevonden",
  "DOWNGRADE_ALTERNATIVE_DESCRIPTION": "Wat voor alternatief?",
  "DOWNGRADE_MISSING_FEATURE": "Mis een feature",
  "DOWNGRADE_MISSING_FEATURE_DESCRIPTION": "Welke feature mis je?",
  "DOWNGRADE_PRICING": "Te duur",
  "DOWNGRADE_PRICING_DESCRIPTION": "Opmerkingen",
  "DOWNGRADE_UNSATISFIED": "Niet tevreden",
  "DOWNGRADE_UNSATISFIED_DESCRIPTION": "Wat kunnen wij verbeteren?",
  "DOWNGRADE_OTHER": "Anders",
  "DOWNGRADE_OTHER_DESCRIPTION": "Wat is jouw reden?",
  "SELECT_OPTION": "Selecteer een optie",
  "CONTACT_BUTTON": "Neem contact op",
  "CONTACT_LINK": "https://calendly.com/moreapp",
  "PRICE_LABEL_NOW": "nu",
  "PRICE_LABEL_UPCOMING": "vanaf januari 2024",
  "PRICE_ANNUAL_BILLED_MONTHLY": "Jaarlijks gefactureerd",
  "BILLING_FLOW_STEP1_LABEL": "Selecteer abonnement",
  "BILLING_FLOW_STEP2_LABEL": "Betaalinformatie",
  "BILLING_FLOW_STEP3_LABEL": "Betaalmethode",
  "BILLING_FLOW_STEP4_LABEL": "Bevestig",
  "ONETIME": "Eenmalig",
  "MONTHLY": "Maandelijks",
  "YEARLY": "Jaarlijks",
  "RECURRENCE": "Herhaling",
  "BRANDING": "Branding",
  "RESTORE": "Herstellen",
  "RESTORE_FORM_MESSAGE": "Als je dit formulier herstelt, breng je het formulier terug in het Platform en in de app. ",
  "SIGN_UP_CUSTOMER_ACCOUNT_TITLE": "Maak een account aan",
  "SIGN_UP_CUSTOMER_ACCOUNT_SUB_TITLE": "Start gratis 14-daagse proefperiode",
  "SIGN_UP_CUSTOMER_EMAIL_ADDRESS": "E-mailadres",
  "SIGN_UP_CUSTOMER_PASSWORD": "Wachtwoord",
  "SIGN_UP_CUSTOMER_BUTTON": "Account aanmaken",
  "EMAIL_ADDRESS_PLACEHOLDER": "e-mail@bedrijfsnaam.nl",
  "EMAIL_VALIDATION_VALIDATING": "E-mail valideren...",
  "EMAIL_VALIDATION_FAILED": "E-mail valideren mislukt. We konden het account correspondered met het e-mailadres niet vinden. Jouw account is mogelijk al gevalideerd.",
  "EMAIL_VALIDATION_SUCCESS": "Jouw e-mailadres is succesvol gevalideerd!",
  "WIDGET_PIN_CLICK_IMAGE_TO_ADD": "Klik op de afbeelding om een Pin toe te voegen",
  "ALERT_TOUCH_DEVICE": "Het platform werkt niet optimaal op mobiele toestellen. Gebruik een laptop of PC",
  "ALERT_PAYMENT_METHOD_EXPIRED": "<b>De gekozen betaalmethode is verlopen!</b> Zorg ervoor dat je <a href ui-sref='portal.customers.billing.overview'>een nieuwe betaalmethode toevoegt</a> om formulieren te kunnen versturen.",
  "ADD_VIEW_TEMPLATE_TYPE": "Gebruik template:",
  "ADD_VIEW_TEMPLATE_TYPE_NONE": "Blanco",
  "ADD_VIEW_TEMPLATE_TYPE_FORM_TEMPLATE": "Geïnstalleerd",
  "ADD_VIEW_TEMPLATE_TYPE_GET_MORE_TEMPLATES": "Vind meer formulieren in de Marketplace",
  "BLANK_FORM": "Blanco",
  "BLANK_FORM_DESCRIPTION": "Begin met een leeg formulier",
  "AGREEMENTS_MODAL_TITLE": "Algemene voorwaarden bijgewerkt",
  "AGREEMENTS_MODAL_MESSAGE": "We hebben onze voorwaarden bijgewerkt inclusief de verwerkersafspraken. Lees deze aandachtig door. Mocht je vragen of opmerkingen hebben, neem dan contact met ons op. Anders is er geen actie vereist en zijn de bijgewerkte voorwaarden effectief per 1 februari 2019.",
  "AGREEMENTS_MODAL_ACCEPT_BUTTON": "Doorgaan",
  "ADD_PHOTO": "Voeg foto toe",
  "CAPTURE_PHOTO": "Maak foto",
  "BROWSE_PHOTO": "Zoek foto's",
  "DRAW_SIGNATURE": "Plaats handtekening",
  "START_DRAWING": "Begin met ondertekenen",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_BACKGROUND_IMAGE": "Kies achtergrondafbeelding",
  "ADD_VIDEO": "Voeg video toe",
  "SIGN_UP_THANKS": "Bedankt voor het aanmelden",
  "SIGN_UP_CHECK_MAIL": "We hebben je een mail gestuurd ter verificatie van je account.",
  "FORM_BUILDER": "Form Builder",
  "MOBILE_APP": "App",
  "PDF_REPORTS": "PDF-bestanden",
  "EXCEL_EXPORT": "Excel Export",
  "TASKS": "Taken",
  "PDF_LOGO": "Jouw logo op de PDF",
  "REALTIME_DATA_SOURCES": "Automatische data import",
  "PDF_LAYOUT": "Gepersonaliseerde PDF",
  "RULES": "Regels",
  "API_ACCESS": "API toegang",
  "COLOR_THEMES": "Kleurenthema's",
  "THEMES_SYSTEM": "Standaard thema's",
  "THEMES_CUSTOM": "Eigen thema's",
  "GROUP_ROLES": "Groep Rollen & Rechten",
  "CUSTOM_DOMAIN": "Eigen domein",
  "DEDICATED_ACCOUNT_MANAGER": "Dedicated support",
  "PREMIUM_SUPPORT": "Dedicated support",
  "FILE_SELECT": "Selecteer een bestand",
  "NEW_CUSTOMER": "+ Nieuwe Klant",
  "TO_PORTAL": "Naar het Platform",
  "EXPIRED": "Verlopen",
  "READ_MORE": "Lees meer",
  "CHOOSE_A_PLAN": "Kies een Abonnement",
  "TRIAL": "Proefperiode",
  "TRIAL_BANNER_TITLE": "Je zit in een gratis proefperiode",
  "TRIAL_BANNER_DAYS_LEFT": "{{daysLeft}} dagen te gaan",
  "TRIAL_BANNER_SUBMISSIONS_LEFT": "{{submissionsLeft}} registraties over",
  "UPGRADE_NOW": "Upgrade nu",
  "CONTACT_SALES": "Neem contact met ons op",
  "BANNER_DELETION_LABEL": "Jouw account wordt verwijderd over {{days}} dag(en)",
  "BANNER_DELETE_TODAY_LABEL": "Jouw account wordt vandaag verwijderd",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_SUBJECT": "Kies een abonnement om MoreApp te blijven gebruiken",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_BODY": "Kies een abonnement om MoreApp te blijven gebruiken. Anders zal dit account worden verwijderd in {{days}} dag(en).",
  "BANNER_NO_SUBSCRIPTION_TITLE": "Je hebt op dit moment geen actief abonnement",
  "BANNER_NO_SUBSCRIPTION_DESCRIPTION": "Selecteer een abonnement om door te gaan",
  "BANNER_MAINTENANCE_ANNOUNCEMENT": "<b>Belangrijk:</b> In verband met gepland <a href='https://help.moreapp.com/support/solutions/articles/13000100648' target='_blank'>onderhoud</a>, is MoreApp niet beschikbaar op woensdag 19-04-2023 tussen 19.00-21.00 CET",
  "BANNER_MAINTENANCE_ANNOUNCEMENT_BRANDED": "<b>Belangrijk:</b> In verband met gepland onderhoud, is het Product niet beschikbaar op woensdag 19-04-2023 tussen 19.00-21.00 CET.",
  "BANNER_OVER_USAGE_TITLE": "Je hebt het aantal gratis registraties overschreden.",
  "BANNER_OVER_USAGE_DESCRIPTION": "<b>Je hebt het aantal gratis registraties overschreden.</b> ✈️ {{ nrOfSubmissionsOverIncluded }} extra registraties 💰 {{ (extraUsageCostsInCents / 100 | currencySymbol:'eur') }} extra kosten",
  "BANNER_OVER_USAGE_CTA_UPGRADE": "Upgrade nu",
  "BANNER_OVER_USAGE_CTA_CONTACT": "Neem contact op",
  "BANNER_CREDIT_CARD_EXPIRED_TITLE": "Jouw creditcard is verlopen.",
  "BANNER_CREDIT_CARD_EXPIRED_DESCRIPTION": "Voeg een geldige betaalmethode toe.",
  "BANNER_CREDIT_CARD_EXPIRED_PRIMARY_ACTION": "Betaalmethode Toevoegen",
  "BANNER_PAST_DUE_INVOICE_TITLE": "Je hebt een openstaande factuur.",
  "BANNER_PAST_DUE_INVOICE_DESCRIPTION": "Betaal je factuur voordat jouw account wordt geblokkeerd.",
  "BANNER_PAST_DUE_INVOICE_PRIMARY_ACTION": "Betaal Factuur",
  "BANNER_UNPAID_INVOICE_TITLE": "Je account is tijdelijk geblokkeerd door een openstaande factuur.",
  "BANNER_UNPAID_INVOICE_DESCRIPTION": "Betaal jouw factuur om te deblokkeren.",
  "BANNER_UNPAID_INVOICE_PRIMARY_ACTION": "Betaal Factuur",
  "DATA_SOURCE_MANAGEMENT_ENTRIES_SELECT_COLUMNS": "Selecteer kolommen",
  "DATA_SOURCE_MANAGEMENT_EDIT": "Wijzigen",
  "DATA_SOURCE_MANAGEMENT_UPLOAD_EXCEL": "Excel Uploaden",
  "DATA_SOURCE_MANAGEMENT_SCHEDULE_URL_IMPORT": "Importeer",
  "DATA_SOURCE_MANAGEMENT_SEARCH": "Zoeken",
  "DATA_SOURCE_MANAGEMENT_ENABLE": "Activeer",
  "DATA_SOURCES_TITLE": "Databronnen",
  "DATA_SOURCES_LAST_UPDATED_LABEL": "Laatste update",
  "DATA_SOURCES_GENERAL_INFORMATION": "Algemene Informatie",
  "DATA_SOURCES_LAST_SUCCESSFULL_UPDATE_LABEL": "Laatste succesvolle update",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE_WARNINGS": "Toon waarschuwingen",
  "DATA_SOURCES_URL_CONFIGURATION_LABEL": "URL Configuratie",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_BUTTON": "Importeer",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_ACTIVE": "Bezig...",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_BUTTON": "Importeer",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_ACTIVE": "Bezig...",
  "DATA_SOURCES_WARNINGS_LAST_WARNINGS": "Meest recente waarschuwingen:",
  "DATA_SOURCES_WARNINGS_SHOW_ALL": "( Alle {{numberOfWarningMessages}} waarschuwingen )",
  "DATA_SOURCES_WARNINGS_SHOW_LESS": "( Minder waarschuwingen )",
  "DATA_SOURCE_UPLOAD_EXCEL_TITLE": "Excel bestand uploaden",
  "DATA_SOURCE_UPLOAD_EXCEL_FILE_TOO_LARGE_MESSAGE": "Het geselecteerde bestand is te groot om te uploaden. Het bestandslimiet voor Excel-bestanden is 10 MB.",
  "DATA_SOURCE_UPLOAD_EXCEL_MESSAGE": "Selecteer een Excel bestand. Zorg ervoor dat de bestandsextensie '.xlsx' of '.xls' is.",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_HERE": "sleep bestanden hier",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_NOT_SUPPORTED": "HTML5 Drop File is niet ondersteund!",
  "DATA_SOURCE_MANAGEMENT_ENTRY_DETAIL_TITLE": "Details",
  "DATA_SOURCES_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCES_URL_CONFIGURATION_PARAMETERS_LABEL": "Parameters",
  "DATA_SOURCES_LAST_UPDATE_TYPE_LABEL": "Laatste waarschuwings type",
  "DATA_SOURCES_SEARCH_LABEL": "Databron",
  "DATA_SOURCES_ADD_BUTTON": "+ Toevoegen",
  "ADD_DATA_SOURCE_PANEL_TITLE": "Toevoegen",
  "EDIT_DATA_SOURCE_PANEL_TITLE": "Wijzigen",
  "DATA_SOURCE_PANEL_NAME_LABEL": "Naam",
  "DATA_SOURCE_PANEL_NAME_PLACEHOLDER": "Kies een naam voor de databron",
  "DATA_SOURCE_PANEL_TYPE_LABEL": "Type",
  "DATA_SOURCE_PANEL_TYPE_EXCEL_LABEL": "Excel",
  "DATA_SOURCE_PANEL_TYPE_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_TYPE_GOOGLE_SPREADSHEET_LABEL": "Google Spreadsheet",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_PLACEHOLDER": "Vul een URL in",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAMETERS_LABEL": "Parameters",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_ADD_PARAMETER": "Toevoegen",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_NO_PARAMETERS": "Geen parameters",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_KEY": "Parameter",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_VALUE": "Waarde",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_LABEL": "Aanvraag header",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_ADD_BUTTON": "Toevoegen aanvraag header",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_KEY": "Key",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_VALUE": "Waarde",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_INTERVAL_LABEL": "Automatische import",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_BASIC_AUTH": "Basic Authentication",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_AUTHENTICATE": "Authenticeer with Google",
  "AUTHENTICATED_WITH_GOOGLE": "Geauthenticeerd met Google",
  "CHANGE_ACCOUNT": "wijzig account",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_LABEL": "Google Spreadsheet ID",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_PLACEHOLDER": "Waarde tussen /d/ en /edit in de URL van jouw Google spreadsheet",
  "DATA_SOURCE_GRID_NO_ENTRIES": "Deze databron is leeg",
  "DATA_SOURCE_GRID_LOADING_MESSAGE": "Laden",
  "DATA_SOURCE_GRID_IMPORTING_ENTRIES": "Importeren",
  "DATA_SOURCE_MANAGEMENT_LAST_FAILED": "Laatst gefaalde update",
  "DATA_SOURCE_MANAGEMENT_NO_UPDATE": "Laatst bijgewerkt: nooit",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE": "Laatst bijgewerkt",
  "DATA_SOURCE_WARNINGS_TITLE": "Importeer waarschuwingen",
  "DATA_SOURCE_REMOVE_TITLE": "Verwijderen",
  "DATA_SOURCE_REMOVE_QUESTION": "Weet je zeker dat je deze databron wil verwijderen? Je kan deze actie niet ongedaan maken!",
  "DATA_SOURCE_ENABLE_TITLE": "Activeren",
  "DATA_SOURCE_ENABLE_MESSAGE": "De databron is uitgezet omdat 10 of meer importeeracties zijn mislukt. Controleer dat de instellingen kloppen voordat deze opnieuw geactiveerd wordt.",
  "CLIENTS": "Apps",
  "CLIENTS_TITLE": "Apps",
  "SETTINGS_TITLE": "Instellingen",
  "SETTINGS_TAB_GENERAL": "Algemeen",
  "SETTINGS_TAB_SSO": "Single sign-on",
  "SETTINGS_TAB_SENDING_DOMAIN": "E-mail domein",
  "BILLING_SUCCESS_MESSAGE": "Betaalgegevens succesvol gewijzigd.",
  "BILLING_DETAILS_HEADER": "Klant",
  "BILLING_INVOICES_HEADER": "Klant",
  "GENERAL_HEADER": "Algemeen",
  "GENERAL_SUCCESS_MESSAGE": "Klantinstellingen succesvol gewijzigd.",
  "LOGO_HEADER": "Logo",
  "LOGO_SELECT": "Selecteer logo",
  "SETTINGS_DATE_FORMAT": "Datumnotatie",
  "SETTINGS_CUSTOMER_NAME": "Bedrijfsnaam",
  "SETTINGS_TAB_THEMES": "Thema's",
  "SETTINGS_TAB_THEMES_CUSTOM": "Thema's",
  "SETTINGS_TAB_THEMES_ADD_BUTTON": "Toevoegen",
  "THEME_FORM": "Thema",
  "THEME_FORM_NAME_LABEL": "Naam",
  "THEME_FORM_BUTTON_LABEL": "Knop",
  "THEME_FORM_SAVE_BUTTON_LABEL": "Verstuur knop",
  "THEME_FORM_BAR_LABEL": "Balk",
  "THEME_FORM_BACKGROUND_LABEL": "Achtergrond",
  "THEME_FORM_WIDGET_LABEL": "Widget",
  "SETTINGS_TAB_SERVICE_ACCOUNTS": "Service Accounts",
  "SETTINGS_TAB_WEBHOOKS": "Webhooks",
  "SETTINGS_TAB_APIKEYS": "API keys",
  "TOKEN": "Token",
  "API_KEY_NEW": "Nieuwe API key",
  "API_KEY_CREATED": "Nieuwe API key aangemaakt",
  "API_KEY_SAFETY": "Sla veilig op",
  "API_KEY_CREATED_MESSAGE": "Bewaar deze API key op een veilige manier. Gebruik hiervoor een password manager. <br><b>We tonen deze key later niet meer.</b",
  "API_KEY_REMOVE_TITLE": "Verwijder API key?",
  "API_KEY_REMOVE_MESSAGE": "Met het verwijderen van de key is het mogelijk dat integraties die deze key gebruiken niet meer werken! Je kunt het verwijderen van deze key niet meer terugdraaien.",
  "WEBHOOK_DETAILS": "Webhook details",
  "WEBHOOK_ATTEMPTS": "Webhook pogingen",
  "WEBHOOK_ATTEMPTS_MESSAGE": "Pogingen voor het versturen van een event naar jouw endpoint in de afgelopen 30 dagen",
  "WEBHOOK_REQUEST_BODY": "Request body",
  "WEBHOOK_REQUEST_BODY_MESSAGE": "De payload die we naar jouw endpoint verzonden hebben",
  "WEBHOOK_RESPONSE": "Response",
  "WEBHOOK_RESPONSE_MESSAGE": "De response die we van jouw endpoint ontvangen hebben",
  "WEBHOOK_REMOVE_TITLE": "Webhook verwijderen?",
  "WEBHOOK_REMOVE_MESSAGE": "Weet je zeker dat je deze webhook wil verwijderen? Wist je dat je de webhook ook kunt <b>deactiveren</b> vanuit de detailweergave?",
  "ADD_SUBSCRIBER": "Voeg een subsciber toe",
  "EVENT_TYPES": "Event types",
  "SELECT_EVENT_TYPES": "Selecteer de event typen waarop je je wil subscriben",
  "SECRET": "Verborgen",
  "SERVICE_ACCOUNTS_NAME": "Naam",
  "SERVICE_ACCOUNTS_TYPE": "Type",
  "SERVICE_ACCOUNTS_ACTIONS": "Acties",
  "SERVICE_ACCOUNTS_RECONNECT": "Opnieuw verbinden",
  "SERVICE_ACCOUNTS_DELETE": "Verwijder",
  "SERVICE_ACCOUNTS_ADD": "Voeg Service Account toe",
  "SERVICE_ACCOUNTS_NON_FOUND": "Geen service accounts geconfigureerd",
  "SERVICE_ACCOUNTS_LOAD_FAILED": "Service accounts laden is mislukt",
  "SERVICE_ACCOUNTS_UPDATE_FAILED": "Service account bijwerken is mislukt",
  "SERVICE_ACCOUNTS_CREATE_FAILED": "Service account maken is mislukt",
  "SERVICE_ACCOUNTS_RECONNECT_FAILED": "Service account opnieuw verbinden is mislukt",
  "SERVICE_ACCOUNTS_DELETE_FAILED": "Service account verwijderen is mislukt",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_TITLE": "Verwijder service account",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_MESSAGE": "Weet je zeker dat je service account <b>'{{ displayName }}'</b> wil verwijderen? <br><br> Formulieren met integraties die gebruik maken van dit service account zullen niet meer werken.",
  "GET_STARTED": "Begin nu",
  "MANAGE": "Beheer",
  "SSO_DSYNC": "Directory sync",
  "SSO": "Single sign-on",
  "SSO_DESCRIPTION": "Verbind je bedrijfsdirectory en meld je aan om het gebruikersbeheer te vereenvoudigen",
  "SSO_SHARED_WARNING": "Deze single sign-on (SSO) configuratie wordt gebruikt door meerdere klanten, wijzigingen die worden gedaan zijn van toepassing op alle accounts.",
  "FEATURE_SINGLE_SIGN_ON_MESSAGE": "<h4>Gebruik single sign-on om je aan te melden met de inloggegevens die je voor alle applicaties gebruikt.</h4><p></p><br><p>Deze functie is beschikbaar vanaf het <b>Forest</b> abonnement.</p>",
  "SSO_CONNECTION_ACTIVE": "Actief",
  "SSO_CONNECTION_INACTIVE": "Inactief",
  "SSO_CONNECTION_DRAFT": "Concept",
  "SSO_CONNECTION_PENDING": "In afwachting",
  "DSYNC_CONNECTION_LINKED": "Gelinkt",
  "DSYNC_CONNECTION_UNLINKED": "Niet gelinkt",
  "DSYNC_CONNECTION_PENDING": "In afwachting",
  "SSO_STEP_1_TITLE": "Begin Nu",
  "SSO_STEP_2_TITLE": "Configureer Single Sign-On (SSO)",
  "SSO_STEP_2_DESCRIPTION": "Koppel met jouw identity provider",
  "SSO_STEP_3_TITLE": "Configureer Directory Synchronisatie",
  "SSO_STEP_3_DESCRIPTION": "Synchroniseer jouw groepen en gebruikers om deze up-to-date te houden",
  "SSO_STEP_4_TITLE": "SSO ingeschakeld",
  "SSO_STEP_4_DESCRIPTION": "Jouw gebruikers kunnen nu SSO gebruiken om in te loggen",
  "SSO_IMPORTED_USERS": "{{count}} geïmporteerde gebruikers uit jouw directory",
  "SSO_IMPORTED_GROUPS": "{{count}} geïmporteerde groepen uit jouw directory",
  "SSO_NO_IMPORTED_USERS_GROUPS": "Geen geïmporteerde gebruikers of groepen",
  "SENDING_DOMAIN_TITLE": "Stel jouw e-mail domein in",
  "SENDING_DOMAIN_DESCRIPTION": "Maak e-mails herkenbaar en in lijn met jouw bedrijfsstijl. We versturen e-mails (zoals PDF rapporten) vanaf  <b>mycompany.com</b> in plaats van moreapp.com.",
  "SENDING_DOMAIN_SHARED_WARNING": "Dit e-mail domein wordt gebruikt door meerdere klanten, wijzigingen die worden gedaan zijn van toepassing voor alle accounts.",
  "SENDING_DOMAIN_EDIT_SUBTITLE": "Om een ander domein te wijzigen, dien je deze te verwijderen en een nieuwe toe te voegen",
  "SENDING_DOMAIN_FIELD_DOMAIN": "Domein",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_LABEL": "Domein",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_DESCRIPTION": "Het domein wordt gebruikt om e-mails te verzenden van  <a>mycompany.com</a>",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_LABEL": "Default From Name",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_DESCRIPTION": "Kies een naam of titel die herkenbaar is voor jouw ontvangers. In de meeste gevallen zal dit de bedrijfsnaam zijn.",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_LABEL": "Default From Email",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_DESCRIPTION": "De Van e-mail wordt gebruikt als het originele e-mailadres. Dit is ook het Antwoord naar e-mailadres",
  "SENDING_DOMAIN_DELETE_TITLE": "Verwijder het gepersonaliseerde e-mail domein?",
  "SENDING_DOMAIN_DELETE_MESSAGE": "Dit betekent dat jouw e-mails worden verstuurd vanaf het standaard domein. Je hebt de DNS records niet langer nodig. Je kunt deze verwijderen van jouw e-mail provider.",
  "SENDING_DOMAIN_VERIFY_TITLE": "Voeg DNS Records toe",
  "SENDING_DOMAIN_VERIFY_DESCRIPTION": "Voeg deze DNS records toe aan jouw domein provider om jouw domein te verifiëren. De verificatie kan tot 48 uur duren terwijl de wijzigingen worden doorgevoerd.",
  "SENDING_DOMAIN_VERIFY_BTN": "Verifieer records",
  "SENDING_DOMAIN_ERROR_INVALID_DOMAIN": "Ongeldig domein '{{domain}}'",
  "SENDING_DOMAIN_ERROR_INVALID_FROM_EMAIL": "De Van e-mail moet ingesteld zijn als het '{{domain}}' domein",
  "FEATURE_SENDING_DOMAIN_MESSAGE": "<h4>Verstuur e-mails (zoals PDF rapporten) vanaf jouw bedrijfsdomein </h4><p> Deze feature is beschikbaar vanaf het <b>Branch</b> abonnement.</p>",
  "SETTINGS_SUCCESS_MESSAGE": "Je instellingen zijn opgeslagen",
  "DYNAMIC_RECIPIENTS_ADD": "Ontvanger toevoegen",
  "DYNAMIC_RECIPIENTS_DEFAULT_HEADER": "Standaard",
  "DYNAMIC_RECIPIENTS_EMAIL_HEADER": "Email velden",
  "DYNAMIC_RECIPIENTS_OTHER_HEADER": "Andere velden",
  "TASKS_NEW": "Nieuwe taak",
  "TASKS_PUBLISH_DATE": "Gepubliceerd",
  "TASKS_MESSAGE": "Bericht",
  "TASKS_AND_X_OTHERS": "en {{others}} meer",
  "TASKS_FAILED_TO_LOAD_MESSAGE": "Er was een storing tijdens het laden van de taken. Excuses voor het ongemak. Je kunt proberen de pagina te vernieuwen.",
  "TASKS_APPLICATION_WITHOUT_VERSION": "Deze map is niet gepubliceerd. Om taken te maken moet je eerst de map publiceren.",
  "TASK_ADD_TITLE": "Nieuwe taak",
  "TASK_ADD_USERS_OR_GROUPS_PLACEHOLDER": "Selecteer gebruiker(s) en/of groep(en)",
  "TASK_ADD_USERS_OR_GROUPS_NUMBER_OF_USERS_IN_GROUP": "{{numberOfUsers}} gebruiker(s)",
  "TASK_ADD_USERS_OR_GROUPS_NO_USERS_IN_GROUP": "Er zijn geen gebruikers aan deze groep toegevoegd",
  "TASK_ADD_USERS_OR_GROUPS_USERS_IN_THIS_GROUP": "Gebruikers in deze groep:",
  "TASK_ADD_MESSAGE_LABEL": "Bericht",
  "TASK_ADD_NO_USERS_OR_GROUPS_MESSAGE": "Er zijn geen gebruikers of groepen met toegang tot dit formulier. Je kunt gebruikers rechten geven onder 'Gebruikers Beheren' in het formulier overzicht.",
  "TASK_ADD_PUBLISH_DATE_LABEL": "Verstuur taak",
  "TASK_DETAIL_TITLE": "Taak",
  "TASK_DETAIL_MESSAGE": "Bericht",
  "TASK_DETAIL_ACTIONS": "Acties",
  "TASK_DETAIL_ACTION_COMPLETE": "Voltooi",
  "TASK_DETAIL_ACTION_REVOKE": "Intrekken",
  "TASKS_GRID_NO_ENTRIES": "Er zijn geen taken voor dit formulier",
  "TASKS_ADD_PUBLISH_IMMEDIATE": "Onmiddelijk",
  "TASKS_ADD_PUBLISH_IN_ONE_HOUR": "Over 1 uur",
  "TASKS_ADD_PUBLISH_IN_TWO_HOURS": "Over 2 uur",
  "TASKS_ADD_PUBLISH_TOMORROW": "Morgen",
  "TASK_DETAIL_FULFILMENT_STATUS_COMPLETED": "Voltooid",
  "TASK_DETAIL_FULFILMENT_STATUS_PENDING": "In behandeling",
  "TASK_DETAIL_FULFILMENT_STATUS_DECLINED": "Geweigerd",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_REVOKED": "Ingetrokken",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_COMPLETED": "Voltooid",
  "INTEGRATION_GOOGLESHEETS_SUCCESS_MESSAGE": "Registratie toegevoegd aan rij: {{range}}",
  "INTEGRATION_TASK_SUCCESS_MESSAGE": "Taken aangemaakt voor:",
  "INTEGRATION_TASK_NO_RECIPIENTS_MESSAGE": "Er is geen taak aangemaakt omdat er geen ontvangers zijn toegevoegd",
  "INTEGRATION_WORD_SUCCESS_MESSAGE": "Het rapport is verstuurd naar:",
  "INTEGRATION_EXCEL_SUCCESS_MESSAGE": "Het rapport is verstuurd naar:",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO_SELECT_BUTTON": "Vervang standaardlogo"
}
;